import IntroHeader from "../../utils/intro-header";
import FooterView from "../footer-view";
import AdvantagesView from "./advantages-view";
import HeroSectionView from "./hero-section-view";
import InformationView from "./information-view";
import NewsfeedSectionView from "./newsfeed-view";
import OurServiceView from "./our-service-view";


function IntroductionMainView() {

    return (
        <div className="flex flex-col bg-[#F3F8FD]">
            <IntroHeader className="sticky top-0" />
            <HeroSectionView />
            <div className="pt-[110px]">
                <AdvantagesView />
            </div>
            <div className="pt-[110px]">
                <OurServiceView />
            </div>
            <div className="pt-[110px]">
                <NewsfeedSectionView />
            </div>
            <div className="pt-[110px]">
                <InformationView />
            </div>
            <div className="mt-[110px]">
                <FooterView />
            </div>
        </div>
    );
}

export default IntroductionMainView;
