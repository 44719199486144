import { Link, Outlet } from "react-router-dom";
import WebIcon from "../../../../component/utils/Icon";

function IntroHeader(props) {

    return (
        <div className={`${props.className} bg-[#ffffff1a] grid grid-cols-2 justify-between items-center p-3 border-b h-[104px] backdrop-blur-xl z-50`}>
            <Link to={"/"}>
                <WebIcon />
            </Link>
            <div className="flex items-center justify-center gap-5 text-xs p-5">
                <div className="text-center p-2 hover:text-white hover:bg-[#1CB78D] hover:rounded-3xl">Хөрөнгө оруулалтын сан</div>
                <Link to={"/aboutus"}>
                    <div className="text-center p-2 hover:text-white hover:bg-[#1CB78D] hover:rounded-3xl">Бидний тухай</div>
                </Link>
                <div className="text-center p-2 hover:text-white hover:bg-[#1CB78D] hover:rounded-3xl">Холбоо барих</div>
                <div className="p-2 text-center bg-[#1CB78D] rounded-3xl text-white">Нэвтрэх</div>
            </div>
        </div>
    );
}

export default IntroHeader;
