import { Briefcase, Call, Facebook, Location, MessageCircle } from "iconsax-react";
import AboutusBanner from "../../../../component/utils/AboutusBanner";
import KiteRight from "../../../../component/utils/KiteRight";
import KiteLeft from "../../../../component/utils/KiteLeft";
import IntroHeader from "../../utils/intro-header";
import UtilityView from "./utility-view.js";
import BoardView from "./board-view.js";
import OrganisationView from "./organisation-view";
import ExecuteBranchView from "./execute-branch-view";
import FooterView from "../footer-view";
import HeroSVG from "../../../../component/utils/details/hero-svg";
import DotPattern from "../../../../component/utils/DotPattern";
import ChartBoard from "../../../../component/utils/strategy/char-board";
import Lamp from "../../../../component/utils/strategy/lamp";
import SandBox from "../../../../component/utils/strategy/sandbox";


function DetailedIntroView(props) {

    return (
        <div className="flex flex-col">
            <IntroHeader />
            <AboutusBanner />
            <div className="relative flex flex-col bg-[#F3F8FD]">
                <DotPattern extraSpace={true} className="absolute left-0 bottom-0" />
                <div className="grid grid-cols-5 gap-3 my-20 px-[228px]">
                    <div className="col-span-2 flex flex-col m-auto gap-[32px]">
                        <div className="flex flex-wrap text-5xl font-bold">
                            Хөрөнгө оруулалтын
                            <div className="text-primary font-extrabold mx-2">менежментийн</div>
                            компани
                        </div>
                        <div className="z-20 text-lg text-justify font-thin">Бидний  зорилго  нь  хувьцаа  эзэмшигчдийн  үнэ  цэнийг  бий  болгохоос гадна компаниудын үйл ажиллагаанаас Байгаль орчин, нийгэм, засаглал БОНЗ/-д үзүүлэх үр нөлөөг тооцон оролцогч талуудын урт хугацааны үнэ цэн, тогтвортой өсөлтийг бий болгоход чиглэж буй.</div>
                        <KiteLeft extraSpace={true} className="absolute left-0" />
                    </div>
                    <div className="col-span-3 flex flex-col items-end justify-center gap-3 font-thin text-justify pl-3">
                        <HeroSVG />
                        <KiteRight extraSpace={true} className="absolute right-0" />
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-[228px] my-20 gap-10">
                <div className="flex flex-col gap-3 m-auto justify-center items-center">
                    <div className="text-3xl font-bold">Стратеги</div>
                    Хөрөнгө оруулалтын багцтай холбоотой стратегиудыг таньд харуулж байна
                </div>
                <div className="grid grid-cols-2 gap-5 text-sm text-justify">
                    <div className="flex gap-3 px-5 border-r">
                        <div className="flex items-center justify-center rounded-full min-w-[44px] w-[44px] max-h-[44px] h-[44px] text-primary bg-gradient-to-b from-[#caff331a] to-[#caff3300]" >
                            <ChartBoard />
                        </div>
                        Манай  мэргэжилтнүүдийн  баг  нь  үйлчлүүлэгч  бүрийн  санхүүгийн  зорилго өөрчлөгдөхөд  хөрөнгө  оруулалтын  багцаа  цаг  хугацааны  явцад  тохируулахын зэрэгцээ  үйлчлүүлэгч  бүрийн  онцлог  хэрэгцээ,  хүсч  буй  санхүүгийн  зах  зээлд тохирсон үйлчилгээ, зааварчилгааг санал болгон
                    </div>
                    <div className="flex gap-3">
                        <div className="flex items-center justify-center rounded-full min-w-[44px] w-[44px] max-h-[44px] h-[44px] text-primary bg-gradient-to-b from-[#caff331a] to-[#caff3300]" >
                            <Lamp />
                        </div>
                        Бид хуульд заасан санхүүгийн хэрэгслээр дамжуулан хөрөнгө оруулагчид амалсан өндөр өгөөж, эрсдэлгүй хөрөнгө оруулалтын үйлчилгээг тасралтгүй нэр хүндтэй хүргэнэ
                    </div>
                    <div className="flex gap-3 px-5 border-r">
                        <div className="flex items-center justify-center rounded-full min-w-[44px] w-[44px] max-h-[44px] h-[44px] text-primary bg-gradient-to-b from-[#caff331a] to-[#caff3300]" >
                            <Briefcase size={28} variant="Bold" />
                        </div>
                        Хөрөнгө  оруулалтыг  олон  улсад  хүлээн  зөвшөөрөгдёөн  санхүүгийг  арга  зүйд тулгуурлан  шинжилгээ  судалгааг  гүйцэтгэх,  үр  ашигтай  төсөлд  хөрөнгө  оруулах боломж бүрдэнэ
                    </div>
                    <div className="flex gap-3">
                        <div className="flex items-center justify-center rounded-full min-w-[44px] w-[44px] max-h-[44px] h-[44px] text-primary bg-gradient-to-b from-[#caff331a] to-[#caff3300]" >
                            <SandBox />
                        </div>
                        Эдийн засгийн өсөлтөд нөлөөлөхүйц томоохон төслүүдэд хөрөнгө оруулалт хийх замаар  хөрөнгийн  өгөөжийг  бий  болгож,  нийгмийн  хариуцлага,  мэргэжлийн  ёс зүйн удирдлага болгоно
                    </div>
                </div>
            </div>
            <UtilityView />
            <OrganisationView />
            <BoardView />
            <ExecuteBranchView />
            <FooterView />
        </div>
    );
}

export default DetailedIntroView;
