
function SandBox(props) {
    return (
        <svg
            className={props.className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g id="Icon">
                <g id="Union">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 11.4711V13.5625C1.75 14.7706 2.72938 15.75 3.9375 15.75H24.0625C25.2706 15.75 26.25 14.7706 26.25 13.5625V11.4711C26.25 10.6356 25.9511 9.82763 25.4074 9.19328L21.5751 4.72223C20.9101 3.94647 19.9394 3.5 18.9177 3.5H9.08233C8.06059 3.5 7.08987 3.94647 6.42493 4.72223L2.5926 9.19328C2.04887 9.82763 1.75 10.6356 1.75 11.4711ZM9.08233 5.25C8.57146 5.25 8.0861 5.47323 7.75363 5.86111L4.52744 9.625H8.22014C9.39038 9.625 10.4832 10.2099 11.1323 11.1835L11.3238 11.4707C11.6483 11.9576 12.1947 12.25 12.7799 12.25H15.2201C15.8053 12.25 16.3517 11.9576 16.6762 11.4707L16.8677 11.1835C17.5168 10.2099 18.6096 9.625 19.7799 9.625H23.4726L20.2464 5.86111C19.9139 5.47323 19.4285 5.25 18.9177 5.25H9.08233Z" fill="#1CB78D" />
                    <path d="M3.28125 17.5C2.43556 17.5 1.75 18.1856 1.75 19.0312V21C1.75 22.933 3.317 24.5 5.25 24.5H22.75C24.683 24.5 26.25 22.933 26.25 21V19.0312C26.25 18.1856 25.5644 17.5 24.7188 17.5H19.7799C18.6096 17.5 17.5168 18.0849 16.8677 19.0585L16.6762 19.3457C16.3517 19.8326 15.8053 20.125 15.2201 20.125H12.7799C12.1947 20.125 11.6483 19.8326 11.3238 19.3457L11.1323 19.0585C10.4832 18.0849 9.39038 17.5 8.22014 17.5H3.28125Z" fill="#1CB78D" />
                </g>
            </g>
        </svg>
    )
}

export default SandBox;