
function DotPattern(props) {
    return (
        <svg
            className={props.className} xmlns="http://www.w3.org/2000/svg" width="336" height="161" viewBox="0 0 336 161" fill="none">
            <g opacity="0.15">
                <path d="M335.151 1.04095V1.73491H332.984V2.77586H331.54V1.73491H329.373V1.04095H331.54V0H332.984V1.04095H335.151Z" fill="#295271" />
                <path d="M335.151 9.36859V10.0626H332.984V11.1035H331.539V10.0626H329.373V9.36859H331.539V8.32764H332.984V9.36859H335.151Z" fill="#295271" />
                <path d="M335.151 17.6962V18.3902H332.984V19.4311H331.539V18.3902H329.373V17.6962H331.539V16.6553H332.984V17.6962H335.151Z" fill="#295271" />
                <path d="M335.151 26.0239V26.7178H332.984V27.7588H331.539V26.7178H329.373V26.0239H331.539V24.9829H332.984V26.0239H335.151Z" fill="#295271" />
                <path d="M335.151 34.3513V35.0452H332.984V36.0862H331.539V35.0452H329.373V34.3513H331.539V33.3103H332.984V34.3513H335.151Z" fill="#295271" />
                <path d="M335.151 42.6786V43.3726H332.984V44.4136H331.539V43.3726H329.373V42.6786H331.539V41.6377H332.984V42.6786H335.151Z" fill="#295271" />
                <path d="M335.151 51.0068V51.7007H332.984V52.7417H331.539V51.7007H329.373V51.0068H331.539V49.9658H332.984V51.0068H335.151Z" fill="#295271" />
                <path d="M335.151 59.3342V60.0281H332.984V61.0691H331.539V60.0281H329.373V59.3342H331.539V58.2932H332.984V59.3342H335.151Z" fill="#295271" />
                <path d="M335.151 67.6616V68.3555H332.984V69.3965H331.539V68.3555H329.373V67.6616H331.539V66.6206H332.984V67.6616H335.151Z" fill="#295271" />
                <path d="M335.151 75.9892V76.6832H332.984V77.7241H331.539V76.6832H329.373V75.9892H331.539V74.9482H332.984V75.9892H335.151Z" fill="#295271" />
                <path d="M335.151 84.3166V85.0106H332.984V86.0515H331.539V85.0106H329.373V84.3166H331.539V83.2756H332.984V84.3166H335.151Z" fill="#295271" />
                <path d="M335.151 92.6445V93.3384H332.984V94.3794H331.539V93.3384H329.373V92.6445H331.539V91.6035H332.984V92.6445H335.151Z" fill="#295271" />
                <path d="M335.151 100.972V101.666H332.984V102.707H331.539V101.666H329.373V100.972H331.539V99.9309H332.984V100.972H335.151Z" fill="#295271" />
                <path d="M335.151 109.299V109.993H332.984V111.034H331.539V109.993H329.373V109.299H331.539V108.259H332.984V109.299H335.151Z" fill="#295271" />
                <path d="M335.151 117.627V118.321H332.984V119.362H331.539V118.321H329.373V117.627H331.539V116.586H332.984V117.627H335.151Z" fill="#295271" />
                <path d="M335.151 125.955V126.649H332.984V127.69H331.539V126.649H329.373V125.955H331.539V124.914H332.984V125.955H335.151Z" fill="#295271" />
                <path d="M335.151 134.282V134.976H332.984V136.017H331.539V134.976H329.373V134.282H331.539V133.241H332.984V134.282H335.151Z" fill="#295271" />
                <path d="M335.151 142.61V143.304H332.984V144.345H331.539V143.304H329.373V142.61H331.539V141.569H332.984V142.61H335.151Z" fill="#295271" />
                <path d="M335.151 150.937V151.631H332.984V152.672H331.539V151.631H329.373V150.937H331.539V149.896H332.984V150.937H335.151Z" fill="#295271" />
                <path d="M335.151 159.265V159.959H332.984V161H331.539V159.959H329.373V159.265H331.539V158.224H332.984V159.265H335.151Z" fill="#295271" />
                <path d="M317.816 1.04095V1.73491H315.649V2.77586H314.205V1.73491H312.038V1.04095H314.205V0H315.649V1.04095H317.816Z" fill="#295271" />
                <path d="M317.816 9.36859V10.0626H315.649V11.1035H314.205V10.0626H312.038V9.36859H314.205V8.32764H315.649V9.36859H317.816Z" fill="#295271" />
                <path d="M317.816 17.6962V18.3902H315.649V19.4311H314.205V18.3902H312.038V17.6962H314.205V16.6553H315.649V17.6962H317.816Z" fill="#295271" />
                <path d="M317.816 26.0239V26.7178H315.649V27.7588H314.205V26.7178H312.038V26.0239H314.205V24.9829H315.649V26.0239H317.816Z" fill="#295271" />
                <path d="M317.816 34.3513V35.0452H315.649V36.0862H314.205V35.0452H312.038V34.3513H314.205V33.3103H315.649V34.3513H317.816Z" fill="#295271" />
                <path d="M317.816 42.6786V43.3726H315.649V44.4136H314.205V43.3726H312.038V42.6786H314.205V41.6377H315.649V42.6786H317.816Z" fill="#295271" />
                <path d="M317.816 51.0068V51.7007H315.649V52.7417H314.205V51.7007H312.038V51.0068H314.205V49.9658H315.649V51.0068H317.816Z" fill="#295271" />
                <path d="M317.816 59.3342V60.0281H315.649V61.0691H314.205V60.0281H312.038V59.3342H314.205V58.2932H315.649V59.3342H317.816Z" fill="#295271" />
                <path d="M317.816 67.6616V68.3555H315.649V69.3965H314.205V68.3555H312.038V67.6616H314.205V66.6206H315.649V67.6616H317.816Z" fill="#295271" />
                <path d="M317.816 75.9892V76.6832H315.649V77.7241H314.205V76.6832H312.038V75.9892H314.205V74.9482H315.649V75.9892H317.816Z" fill="#295271" />
                <path d="M317.816 84.3166V85.0106H315.649V86.0515H314.205V85.0106H312.038V84.3166H314.205V83.2756H315.649V84.3166H317.816Z" fill="#295271" />
                <path d="M317.816 92.6445V93.3384H315.649V94.3794H314.205V93.3384H312.038V92.6445H314.205V91.6035H315.649V92.6445H317.816Z" fill="#295271" />
                <path d="M317.816 100.972V101.666H315.649V102.707H314.205V101.666H312.038V100.972H314.205V99.9309H315.649V100.972H317.816Z" fill="#295271" />
                <path d="M317.816 109.299V109.993H315.649V111.034H314.205V109.993H312.038V109.299H314.205V108.259H315.649V109.299H317.816Z" fill="#295271" />
                <path d="M317.816 117.627V118.321H315.649V119.362H314.205V118.321H312.038V117.627H314.205V116.586H315.649V117.627H317.816Z" fill="#295271" />
                <path d="M317.816 125.955V126.649H315.649V127.69H314.205V126.649H312.038V125.955H314.205V124.914H315.649V125.955H317.816Z" fill="#295271" />
                <path d="M317.816 134.282V134.976H315.649V136.017H314.205V134.976H312.038V134.282H314.205V133.241H315.649V134.282H317.816Z" fill="#295271" />
                <path d="M317.816 142.61V143.304H315.649V144.345H314.205V143.304H312.038V142.61H314.205V141.569H315.649V142.61H317.816Z" fill="#295271" />
                <path d="M317.816 150.937V151.631H315.649V152.672H314.205V151.631H312.038V150.937H314.205V149.896H315.649V150.937H317.816Z" fill="#295271" />
                <path d="M317.816 159.265V159.959H315.649V161H314.205V159.959H312.038V159.265H314.205V158.224H315.649V159.265H317.816Z" fill="#295271" />
                <path d="M300.48 1.04095V1.73491H298.313V2.77586H296.869V1.73491H294.702V1.04095H296.869V0H298.313V1.04095H300.48Z" fill="#295271" />
                <path d="M300.48 9.36859V10.0626H298.313V11.1035H296.869V10.0626H294.702V9.36859H296.869V8.32764H298.313V9.36859H300.48Z" fill="#295271" />
                <path d="M300.48 17.6962V18.3902H298.313V19.4311H296.869V18.3902H294.702V17.6962H296.869V16.6553H298.313V17.6962H300.48Z" fill="#295271" />
                <path d="M300.48 26.0239V26.7178H298.313V27.7588H296.869V26.7178H294.702V26.0239H296.869V24.9829H298.313V26.0239H300.48Z" fill="#295271" />
                <path d="M300.48 34.3513V35.0452H298.313V36.0862H296.869V35.0452H294.702V34.3513H296.869V33.3103H298.313V34.3513H300.48Z" fill="#295271" />
                <path d="M300.48 42.6786V43.3726H298.313V44.4136H296.869V43.3726H294.702V42.6786H296.869V41.6377H298.313V42.6786H300.48Z" fill="#295271" />
                <path d="M300.48 51.0068V51.7007H298.313V52.7417H296.869V51.7007H294.702V51.0068H296.869V49.9658H298.313V51.0068H300.48Z" fill="#295271" />
                <path d="M300.48 59.3342V60.0281H298.313V61.0691H296.869V60.0281H294.702V59.3342H296.869V58.2932H298.313V59.3342H300.48Z" fill="#295271" />
                <path d="M300.48 67.6616V68.3555H298.313V69.3965H296.869V68.3555H294.702V67.6616H296.869V66.6206H298.313V67.6616H300.48Z" fill="#295271" />
                <path d="M300.48 75.9892V76.6832H298.313V77.7241H296.869V76.6832H294.702V75.9892H296.869V74.9482H298.313V75.9892H300.48Z" fill="#295271" />
                <path d="M300.48 84.3166V85.0106H298.313V86.0515H296.869V85.0106H294.702V84.3166H296.869V83.2756H298.313V84.3166H300.48Z" fill="#295271" />
                <path d="M300.48 92.6445V93.3384H298.313V94.3794H296.869V93.3384H294.702V92.6445H296.869V91.6035H298.313V92.6445H300.48Z" fill="#295271" />
                <path d="M300.48 100.972V101.666H298.313V102.707H296.869V101.666H294.702V100.972H296.869V99.9309H298.313V100.972H300.48Z" fill="#295271" />
                <path d="M300.48 109.299V109.993H298.313V111.034H296.869V109.993H294.702V109.299H296.869V108.259H298.313V109.299H300.48Z" fill="#295271" />
                <path d="M300.48 117.627V118.321H298.313V119.362H296.869V118.321H294.702V117.627H296.869V116.586H298.313V117.627H300.48Z" fill="#295271" />
                <path d="M300.48 125.955V126.649H298.313V127.69H296.869V126.649H294.702V125.955H296.869V124.914H298.313V125.955H300.48Z" fill="#295271" />
                <path d="M300.48 134.282V134.976H298.313V136.017H296.869V134.976H294.702V134.282H296.869V133.241H298.313V134.282H300.48Z" fill="#295271" />
                <path d="M300.48 142.61V143.304H298.313V144.345H296.869V143.304H294.702V142.61H296.869V141.569H298.313V142.61H300.48Z" fill="#295271" />
                <path d="M300.48 150.937V151.631H298.313V152.672H296.869V151.631H294.702V150.937H296.869V149.896H298.313V150.937H300.48Z" fill="#295271" />
                <path d="M300.48 159.265V159.959H298.313V161H296.869V159.959H294.702V159.265H296.869V158.224H298.313V159.265H300.48Z" fill="#295271" />
                <path d="M283.145 1.04095V1.73491H280.978V2.77586H279.534V1.73491H277.367V1.04095H279.534V0H280.978V1.04095H283.145Z" fill="#295271" />
                <path d="M283.145 9.36859V10.0626H280.978V11.1035H279.534V10.0626H277.367V9.36859H279.534V8.32764H280.978V9.36859H283.145Z" fill="#295271" />
                <path d="M283.145 17.6962V18.3902H280.978V19.4311H279.534V18.3902H277.367V17.6962H279.534V16.6553H280.978V17.6962H283.145Z" fill="#295271" />
                <path d="M283.145 26.0239V26.7178H280.978V27.7588H279.534V26.7178H277.367V26.0239H279.534V24.9829H280.978V26.0239H283.145Z" fill="#295271" />
                <path d="M283.145 34.3513V35.0452H280.978V36.0862H279.534V35.0452H277.367V34.3513H279.534V33.3103H280.978V34.3513H283.145Z" fill="#295271" />
                <path d="M283.145 42.6786V43.3726H280.978V44.4136H279.534V43.3726H277.367V42.6786H279.534V41.6377H280.978V42.6786H283.145Z" fill="#295271" />
                <path d="M283.145 51.0068V51.7007H280.978V52.7417H279.534V51.7007H277.367V51.0068H279.534V49.9658H280.978V51.0068H283.145Z" fill="#295271" />
                <path d="M283.145 59.3342V60.0281H280.978V61.0691H279.534V60.0281H277.367V59.3342H279.534V58.2932H280.978V59.3342H283.145Z" fill="#295271" />
                <path d="M283.145 67.6616V68.3555H280.978V69.3965H279.534V68.3555H277.367V67.6616H279.534V66.6206H280.978V67.6616H283.145Z" fill="#295271" />
                <path d="M283.145 75.9892V76.6832H280.978V77.7241H279.534V76.6832H277.367V75.9892H279.534V74.9482H280.978V75.9892H283.145Z" fill="#295271" />
                <path d="M283.145 84.3166V85.0106H280.978V86.0515H279.534V85.0106H277.367V84.3166H279.534V83.2756H280.978V84.3166H283.145Z" fill="#295271" />
                <path d="M283.145 92.6445V93.3384H280.978V94.3794H279.534V93.3384H277.367V92.6445H279.534V91.6035H280.978V92.6445H283.145Z" fill="#295271" />
                <path d="M283.145 100.972V101.666H280.978V102.707H279.534V101.666H277.367V100.972H279.534V99.9309H280.978V100.972H283.145Z" fill="#295271" />
                <path d="M283.145 109.299V109.993H280.978V111.034H279.534V109.993H277.367V109.299H279.534V108.259H280.978V109.299H283.145Z" fill="#295271" />
                <path d="M283.145 117.627V118.321H280.978V119.362H279.534V118.321H277.367V117.627H279.534V116.586H280.978V117.627H283.145Z" fill="#295271" />
                <path d="M283.145 125.955V126.649H280.978V127.69H279.534V126.649H277.367V125.955H279.534V124.914H280.978V125.955H283.145Z" fill="#295271" />
                <path d="M283.145 134.282V134.976H280.978V136.017H279.534V134.976H277.367V134.282H279.534V133.241H280.978V134.282H283.145Z" fill="#295271" />
                <path d="M283.145 142.61V143.304H280.978V144.345H279.534V143.304H277.367V142.61H279.534V141.569H280.978V142.61H283.145Z" fill="#295271" />
                <path d="M283.145 150.937V151.631H280.978V152.672H279.534V151.631H277.367V150.937H279.534V149.896H280.978V150.937H283.145Z" fill="#295271" />
                <path d="M283.145 159.265V159.959H280.978V161H279.534V159.959H277.367V159.265H279.534V158.224H280.978V159.265H283.145Z" fill="#295271" />
                <path d="M265.81 1.04095V1.73491H263.643V2.77586H262.198V1.73491H260.031V1.04095H262.198V0H263.643V1.04095H265.81Z" fill="#295271" />
                <path d="M265.81 9.36859V10.0626H263.643V11.1035H262.198V10.0626H260.031V9.36859H262.198V8.32764H263.643V9.36859H265.81Z" fill="#295271" />
                <path d="M265.81 17.6962V18.3902H263.643V19.4311H262.198V18.3902H260.031V17.6962H262.198V16.6553H263.643V17.6962H265.81Z" fill="#295271" />
                <path d="M265.81 26.0239V26.7178H263.643V27.7588H262.198V26.7178H260.031V26.0239H262.198V24.9829H263.643V26.0239H265.81Z" fill="#295271" />
                <path d="M265.81 34.3513V35.0452H263.643V36.0862H262.198V35.0452H260.031V34.3513H262.198V33.3103H263.643V34.3513H265.81Z" fill="#295271" />
                <path d="M265.81 42.6786V43.3726H263.643V44.4136H262.198V43.3726H260.031V42.6786H262.198V41.6377H263.643V42.6786H265.81Z" fill="#295271" />
                <path d="M265.81 51.0068V51.7007H263.643V52.7417H262.198V51.7007H260.031V51.0068H262.198V49.9658H263.643V51.0068H265.81Z" fill="#295271" />
                <path d="M265.81 59.3342V60.0281H263.643V61.0691H262.198V60.0281H260.031V59.3342H262.198V58.2932H263.643V59.3342H265.81Z" fill="#295271" />
                <path d="M265.81 67.6616V68.3555H263.643V69.3965H262.198V68.3555H260.031V67.6616H262.198V66.6206H263.643V67.6616H265.81Z" fill="#295271" />
                <path d="M265.81 75.9892V76.6832H263.643V77.7241H262.198V76.6832H260.031V75.9892H262.198V74.9482H263.643V75.9892H265.81Z" fill="#295271" />
                <path d="M265.81 84.3166V85.0106H263.643V86.0515H262.198V85.0106H260.031V84.3166H262.198V83.2756H263.643V84.3166H265.81Z" fill="#295271" />
                <path d="M265.81 92.6445V93.3384H263.643V94.3794H262.198V93.3384H260.031V92.6445H262.198V91.6035H263.643V92.6445H265.81Z" fill="#295271" />
                <path d="M265.81 100.972V101.666H263.643V102.707H262.198V101.666H260.031V100.972H262.198V99.9309H263.643V100.972H265.81Z" fill="#295271" />
                <path d="M265.81 109.299V109.993H263.643V111.034H262.198V109.993H260.031V109.299H262.198V108.259H263.643V109.299H265.81Z" fill="#295271" />
                <path d="M265.81 117.627V118.321H263.643V119.362H262.198V118.321H260.031V117.627H262.198V116.586H263.643V117.627H265.81Z" fill="#295271" />
                <path d="M265.81 125.955V126.649H263.643V127.69H262.198V126.649H260.031V125.955H262.198V124.914H263.643V125.955H265.81Z" fill="#295271" />
                <path d="M265.81 134.282V134.976H263.643V136.017H262.198V134.976H260.031V134.282H262.198V133.241H263.643V134.282H265.81Z" fill="#295271" />
                <path d="M265.81 142.61V143.304H263.643V144.345H262.198V143.304H260.031V142.61H262.198V141.569H263.643V142.61H265.81Z" fill="#295271" />
                <path d="M265.81 150.937V151.631H263.643V152.672H262.198V151.631H260.031V150.937H262.198V149.896H263.643V150.937H265.81Z" fill="#295271" />
                <path d="M265.81 159.265V159.959H263.643V161H262.198V159.959H260.031V159.265H262.198V158.224H263.643V159.265H265.81Z" fill="#295271" />
                <path d="M248.474 1.04095V1.73491H246.307V2.77586H244.863V1.73491H242.696V1.04095H244.863V0H246.307V1.04095H248.474Z" fill="#295271" />
                <path d="M248.474 9.36859V10.0626H246.308V11.1035H244.863V10.0626H242.696V9.36859H244.863V8.32764H246.308V9.36859H248.474Z" fill="#295271" />
                <path d="M248.474 17.6962V18.3902H246.308V19.4311H244.863V18.3902H242.696V17.6962H244.863V16.6553H246.308V17.6962H248.474Z" fill="#295271" />
                <path d="M248.474 26.0239V26.7178H246.308V27.7588H244.863V26.7178H242.696V26.0239H244.863V24.9829H246.308V26.0239H248.474Z" fill="#295271" />
                <path d="M248.474 34.3513V35.0452H246.308V36.0862H244.863V35.0452H242.696V34.3513H244.863V33.3103H246.308V34.3513H248.474Z" fill="#295271" />
                <path d="M248.474 42.6786V43.3726H246.308V44.4136H244.863V43.3726H242.696V42.6786H244.863V41.6377H246.308V42.6786H248.474Z" fill="#295271" />
                <path d="M248.474 51.0068V51.7007H246.308V52.7417H244.863V51.7007H242.696V51.0068H244.863V49.9658H246.308V51.0068H248.474Z" fill="#295271" />
                <path d="M248.474 59.3342V60.0281H246.308V61.0691H244.863V60.0281H242.696V59.3342H244.863V58.2932H246.308V59.3342H248.474Z" fill="#295271" />
                <path d="M248.474 67.6616V68.3555H246.308V69.3965H244.863V68.3555H242.696V67.6616H244.863V66.6206H246.308V67.6616H248.474Z" fill="#295271" />
                <path d="M248.474 75.9892V76.6832H246.308V77.7241H244.863V76.6832H242.696V75.9892H244.863V74.9482H246.308V75.9892H248.474Z" fill="#295271" />
                <path d="M248.474 84.3166V85.0106H246.308V86.0515H244.863V85.0106H242.696V84.3166H244.863V83.2756H246.308V84.3166H248.474Z" fill="#295271" />
                <path d="M248.474 92.6445V93.3384H246.308V94.3794H244.863V93.3384H242.696V92.6445H244.863V91.6035H246.308V92.6445H248.474Z" fill="#295271" />
                <path d="M248.474 100.972V101.666H246.308V102.707H244.863V101.666H242.696V100.972H244.863V99.9309H246.308V100.972H248.474Z" fill="#295271" />
                <path d="M248.474 109.299V109.993H246.308V111.034H244.863V109.993H242.696V109.299H244.863V108.259H246.308V109.299H248.474Z" fill="#295271" />
                <path d="M248.474 117.627V118.321H246.308V119.362H244.863V118.321H242.696V117.627H244.863V116.586H246.308V117.627H248.474Z" fill="#295271" />
                <path d="M248.474 125.955V126.649H246.308V127.69H244.863V126.649H242.696V125.955H244.863V124.914H246.308V125.955H248.474Z" fill="#295271" />
                <path d="M248.474 134.282V134.976H246.308V136.017H244.863V134.976H242.696V134.282H244.863V133.241H246.308V134.282H248.474Z" fill="#295271" />
                <path d="M248.474 142.61V143.304H246.308V144.345H244.863V143.304H242.696V142.61H244.863V141.569H246.308V142.61H248.474Z" fill="#295271" />
                <path d="M248.474 150.937V151.631H246.308V152.672H244.863V151.631H242.696V150.937H244.863V149.896H246.308V150.937H248.474Z" fill="#295271" />
                <path d="M248.474 159.265V159.959H246.308V161H244.863V159.959H242.696V159.265H244.863V158.224H246.308V159.265H248.474Z" fill="#295271" />
                <path d="M231.139 1.04095V1.73491H228.972V2.77586H227.527V1.73491H225.361V1.04095H227.527V0H228.972V1.04095H231.139Z" fill="#295271" />
                <path d="M231.139 9.36859V10.0626H228.972V11.1035H227.528V10.0626H225.361V9.36859H227.528V8.32764H228.972V9.36859H231.139Z" fill="#295271" />
                <path d="M231.139 17.6962V18.3902H228.972V19.4311H227.528V18.3902H225.361V17.6962H227.528V16.6553H228.972V17.6962H231.139Z" fill="#295271" />
                <path d="M231.139 26.0239V26.7178H228.972V27.7588H227.528V26.7178H225.361V26.0239H227.528V24.9829H228.972V26.0239H231.139Z" fill="#295271" />
                <path d="M231.139 34.3513V35.0452H228.972V36.0862H227.528V35.0452H225.361V34.3513H227.528V33.3103H228.972V34.3513H231.139Z" fill="#295271" />
                <path d="M231.139 42.6786V43.3726H228.972V44.4136H227.528V43.3726H225.361V42.6786H227.528V41.6377H228.972V42.6786H231.139Z" fill="#295271" />
                <path d="M231.139 51.0068V51.7007H228.972V52.7417H227.528V51.7007H225.361V51.0068H227.528V49.9658H228.972V51.0068H231.139Z" fill="#295271" />
                <path d="M231.139 59.3342V60.0281H228.972V61.0691H227.528V60.0281H225.361V59.3342H227.528V58.2932H228.972V59.3342H231.139Z" fill="#295271" />
                <path d="M231.139 67.6616V68.3555H228.972V69.3965H227.528V68.3555H225.361V67.6616H227.528V66.6206H228.972V67.6616H231.139Z" fill="#295271" />
                <path d="M231.139 75.9892V76.6832H228.972V77.7241H227.528V76.6832H225.361V75.9892H227.528V74.9482H228.972V75.9892H231.139Z" fill="#295271" />
                <path d="M231.139 84.3166V85.0106H228.972V86.0515H227.528V85.0106H225.361V84.3166H227.528V83.2756H228.972V84.3166H231.139Z" fill="#295271" />
                <path d="M231.139 92.6445V93.3384H228.972V94.3794H227.528V93.3384H225.361V92.6445H227.528V91.6035H228.972V92.6445H231.139Z" fill="#295271" />
                <path d="M231.139 100.972V101.666H228.972V102.707H227.528V101.666H225.361V100.972H227.528V99.9309H228.972V100.972H231.139Z" fill="#295271" />
                <path d="M231.139 109.299V109.993H228.972V111.034H227.528V109.993H225.361V109.299H227.528V108.259H228.972V109.299H231.139Z" fill="#295271" />
                <path d="M231.139 117.627V118.321H228.972V119.362H227.528V118.321H225.361V117.627H227.528V116.586H228.972V117.627H231.139Z" fill="#295271" />
                <path d="M231.139 125.955V126.649H228.972V127.69H227.528V126.649H225.361V125.955H227.528V124.914H228.972V125.955H231.139Z" fill="#295271" />
                <path d="M231.139 134.282V134.976H228.972V136.017H227.528V134.976H225.361V134.282H227.528V133.241H228.972V134.282H231.139Z" fill="#295271" />
                <path d="M231.139 142.61V143.304H228.972V144.345H227.528V143.304H225.361V142.61H227.528V141.569H228.972V142.61H231.139Z" fill="#295271" />
                <path d="M231.139 150.937V151.631H228.972V152.672H227.528V151.631H225.361V150.937H227.528V149.896H228.972V150.937H231.139Z" fill="#295271" />
                <path d="M231.139 159.265V159.959H228.972V161H227.528V159.959H225.361V159.265H227.528V158.224H228.972V159.265H231.139Z" fill="#295271" />
                <path d="M213.804 1.04095V1.73491H211.637V2.77586H210.192V1.73491H208.025V1.04095H210.192V0H211.637V1.04095H213.804Z" fill="#295271" />
                <path d="M213.804 9.36859V10.0626H211.637V11.1035H210.192V10.0626H208.025V9.36859H210.192V8.32764H211.637V9.36859H213.804Z" fill="#295271" />
                <path d="M213.804 17.6962V18.3902H211.637V19.4311H210.192V18.3902H208.025V17.6962H210.192V16.6553H211.637V17.6962H213.804Z" fill="#295271" />
                <path d="M213.804 26.0239V26.7178H211.637V27.7588H210.192V26.7178H208.025V26.0239H210.192V24.9829H211.637V26.0239H213.804Z" fill="#295271" />
                <path d="M213.804 34.3513V35.0452H211.637V36.0862H210.192V35.0452H208.025V34.3513H210.192V33.3103H211.637V34.3513H213.804Z" fill="#295271" />
                <path d="M213.804 42.6786V43.3726H211.637V44.4136H210.192V43.3726H208.025V42.6786H210.192V41.6377H211.637V42.6786H213.804Z" fill="#295271" />
                <path d="M213.804 51.0068V51.7007H211.637V52.7417H210.192V51.7007H208.025V51.0068H210.192V49.9658H211.637V51.0068H213.804Z" fill="#295271" />
                <path d="M213.804 59.3342V60.0281H211.637V61.0691H210.192V60.0281H208.025V59.3342H210.192V58.2932H211.637V59.3342H213.804Z" fill="#295271" />
                <path d="M213.804 67.6616V68.3555H211.637V69.3965H210.192V68.3555H208.025V67.6616H210.192V66.6206H211.637V67.6616H213.804Z" fill="#295271" />
                <path d="M213.804 75.9892V76.6832H211.637V77.7241H210.192V76.6832H208.025V75.9892H210.192V74.9482H211.637V75.9892H213.804Z" fill="#295271" />
                <path d="M213.804 84.3166V85.0106H211.637V86.0515H210.192V85.0106H208.025V84.3166H210.192V83.2756H211.637V84.3166H213.804Z" fill="#295271" />
                <path d="M213.804 92.6445V93.3384H211.637V94.3794H210.192V93.3384H208.025V92.6445H210.192V91.6035H211.637V92.6445H213.804Z" fill="#295271" />
                <path d="M213.804 100.972V101.666H211.637V102.707H210.192V101.666H208.025V100.972H210.192V99.9309H211.637V100.972H213.804Z" fill="#295271" />
                <path d="M213.804 109.299V109.993H211.637V111.034H210.192V109.993H208.025V109.299H210.192V108.259H211.637V109.299H213.804Z" fill="#295271" />
                <path d="M213.804 117.627V118.321H211.637V119.362H210.192V118.321H208.025V117.627H210.192V116.586H211.637V117.627H213.804Z" fill="#295271" />
                <path d="M213.804 125.955V126.649H211.637V127.69H210.192V126.649H208.025V125.955H210.192V124.914H211.637V125.955H213.804Z" fill="#295271" />
                <path d="M213.804 134.282V134.976H211.637V136.017H210.192V134.976H208.025V134.282H210.192V133.241H211.637V134.282H213.804Z" fill="#295271" />
                <path d="M213.804 142.61V143.304H211.637V144.345H210.192V143.304H208.025V142.61H210.192V141.569H211.637V142.61H213.804Z" fill="#295271" />
                <path d="M213.804 150.937V151.631H211.637V152.672H210.192V151.631H208.025V150.937H210.192V149.896H211.637V150.937H213.804Z" fill="#295271" />
                <path d="M213.804 159.265V159.959H211.637V161H210.192V159.959H208.025V159.265H210.192V158.224H211.637V159.265H213.804Z" fill="#295271" />
                <path d="M196.468 1.04095V1.73491H194.301V2.77586H192.857V1.73491H190.69V1.04095H192.857V0H194.301V1.04095H196.468Z" fill="#295271" />
                <path d="M196.468 9.36859V10.0626H194.301V11.1035H192.857V10.0626H190.69V9.36859H192.857V8.32764H194.301V9.36859H196.468Z" fill="#295271" />
                <path d="M196.468 17.6962V18.3902H194.301V19.4311H192.857V18.3902H190.69V17.6962H192.857V16.6553H194.301V17.6962H196.468Z" fill="#295271" />
                <path d="M196.468 26.0239V26.7178H194.301V27.7588H192.857V26.7178H190.69V26.0239H192.857V24.9829H194.301V26.0239H196.468Z" fill="#295271" />
                <path d="M196.468 34.3513V35.0452H194.301V36.0862H192.857V35.0452H190.69V34.3513H192.857V33.3103H194.301V34.3513H196.468Z" fill="#295271" />
                <path d="M196.468 42.6786V43.3726H194.301V44.4136H192.857V43.3726H190.69V42.6786H192.857V41.6377H194.301V42.6786H196.468Z" fill="#295271" />
                <path d="M196.468 51.0068V51.7007H194.301V52.7417H192.857V51.7007H190.69V51.0068H192.857V49.9658H194.301V51.0068H196.468Z" fill="#295271" />
                <path d="M196.468 59.3342V60.0281H194.301V61.0691H192.857V60.0281H190.69V59.3342H192.857V58.2932H194.301V59.3342H196.468Z" fill="#295271" />
                <path d="M196.468 67.6616V68.3555H194.301V69.3965H192.857V68.3555H190.69V67.6616H192.857V66.6206H194.301V67.6616H196.468Z" fill="#295271" />
                <path d="M196.468 75.9892V76.6832H194.301V77.7241H192.857V76.6832H190.69V75.9892H192.857V74.9482H194.301V75.9892H196.468Z" fill="#295271" />
                <path d="M196.468 84.3166V85.0106H194.301V86.0515H192.857V85.0106H190.69V84.3166H192.857V83.2756H194.301V84.3166H196.468Z" fill="#295271" />
                <path d="M196.468 92.6445V93.3384H194.301V94.3794H192.857V93.3384H190.69V92.6445H192.857V91.6035H194.301V92.6445H196.468Z" fill="#295271" />
                <path d="M196.468 100.972V101.666H194.301V102.707H192.857V101.666H190.69V100.972H192.857V99.9309H194.301V100.972H196.468Z" fill="#295271" />
                <path d="M196.468 109.299V109.993H194.301V111.034H192.857V109.993H190.69V109.299H192.857V108.259H194.301V109.299H196.468Z" fill="#295271" />
                <path d="M196.468 117.627V118.321H194.301V119.362H192.857V118.321H190.69V117.627H192.857V116.586H194.301V117.627H196.468Z" fill="#295271" />
                <path d="M196.468 125.955V126.649H194.301V127.69H192.857V126.649H190.69V125.955H192.857V124.914H194.301V125.955H196.468Z" fill="#295271" />
                <path d="M196.468 134.282V134.976H194.301V136.017H192.857V134.976H190.69V134.282H192.857V133.241H194.301V134.282H196.468Z" fill="#295271" />
                <path d="M196.468 142.61V143.304H194.301V144.345H192.857V143.304H190.69V142.61H192.857V141.569H194.301V142.61H196.468Z" fill="#295271" />
                <path d="M196.468 150.937V151.631H194.301V152.672H192.857V151.631H190.69V150.937H192.857V149.896H194.301V150.937H196.468Z" fill="#295271" />
                <path d="M196.468 159.265V159.959H194.301V161H192.857V159.959H190.69V159.265H192.857V158.224H194.301V159.265H196.468Z" fill="#295271" />
                <path d="M179.133 1.04095V1.73491H176.966V2.77586H175.521V1.73491H173.354V1.04095H175.521V0H176.966V1.04095H179.133Z" fill="#295271" />
                <path d="M179.133 9.36859V10.0626H176.966V11.1035H175.521V10.0626H173.354V9.36859H175.521V8.32764H176.966V9.36859H179.133Z" fill="#295271" />
                <path d="M179.133 17.6962V18.3902H176.966V19.4311H175.521V18.3902H173.354V17.6962H175.521V16.6553H176.966V17.6962H179.133Z" fill="#295271" />
                <path d="M179.133 26.0239V26.7178H176.966V27.7588H175.521V26.7178H173.354V26.0239H175.521V24.9829H176.966V26.0239H179.133Z" fill="#295271" />
                <path d="M179.133 34.3513V35.0452H176.966V36.0862H175.521V35.0452H173.354V34.3513H175.521V33.3103H176.966V34.3513H179.133Z" fill="#295271" />
                <path d="M179.133 42.6786V43.3726H176.966V44.4136H175.521V43.3726H173.354V42.6786H175.521V41.6377H176.966V42.6786H179.133Z" fill="#295271" />
                <path d="M179.133 51.0068V51.7007H176.966V52.7417H175.521V51.7007H173.354V51.0068H175.521V49.9658H176.966V51.0068H179.133Z" fill="#295271" />
                <path d="M179.133 59.3342V60.0281H176.966V61.0691H175.521V60.0281H173.354V59.3342H175.521V58.2932H176.966V59.3342H179.133Z" fill="#295271" />
                <path d="M179.133 67.6616V68.3555H176.966V69.3965H175.521V68.3555H173.354V67.6616H175.521V66.6206H176.966V67.6616H179.133Z" fill="#295271" />
                <path d="M179.133 75.9892V76.6832H176.966V77.7241H175.521V76.6832H173.354V75.9892H175.521V74.9482H176.966V75.9892H179.133Z" fill="#295271" />
                <path d="M179.133 84.3166V85.0106H176.966V86.0515H175.521V85.0106H173.354V84.3166H175.521V83.2756H176.966V84.3166H179.133Z" fill="#295271" />
                <path d="M179.133 92.6445V93.3384H176.966V94.3794H175.521V93.3384H173.354V92.6445H175.521V91.6035H176.966V92.6445H179.133Z" fill="#295271" />
                <path d="M179.133 100.972V101.666H176.966V102.707H175.521V101.666H173.354V100.972H175.521V99.9309H176.966V100.972H179.133Z" fill="#295271" />
                <path d="M179.133 109.299V109.993H176.966V111.034H175.521V109.993H173.354V109.299H175.521V108.259H176.966V109.299H179.133Z" fill="#295271" />
                <path d="M179.133 117.627V118.321H176.966V119.362H175.521V118.321H173.354V117.627H175.521V116.586H176.966V117.627H179.133Z" fill="#295271" />
                <path d="M179.133 125.955V126.649H176.966V127.69H175.521V126.649H173.354V125.955H175.521V124.914H176.966V125.955H179.133Z" fill="#295271" />
                <path d="M179.133 134.282V134.976H176.966V136.017H175.521V134.976H173.354V134.282H175.521V133.241H176.966V134.282H179.133Z" fill="#295271" />
                <path d="M179.133 142.61V143.304H176.966V144.345H175.521V143.304H173.354V142.61H175.521V141.569H176.966V142.61H179.133Z" fill="#295271" />
                <path d="M179.133 150.937V151.631H176.966V152.672H175.521V151.631H173.354V150.937H175.521V149.896H176.966V150.937H179.133Z" fill="#295271" />
                <path d="M179.133 159.265V159.959H176.966V161H175.521V159.959H173.354V159.265H175.521V158.224H176.966V159.265H179.133Z" fill="#295271" />
                <path d="M161.797 1.04095V1.73491H159.63V2.77586H158.186V1.73491H156.019V1.04095H158.186V0H159.63V1.04095H161.797Z" fill="#295271" />
                <path d="M161.797 9.36859V10.0626H159.63V11.1035H158.186V10.0626H156.019V9.36859H158.186V8.32764H159.63V9.36859H161.797Z" fill="#295271" />
                <path d="M161.797 17.6962V18.3902H159.63V19.4311H158.186V18.3902H156.019V17.6962H158.186V16.6553H159.63V17.6962H161.797Z" fill="#295271" />
                <path d="M161.797 26.0239V26.7178H159.63V27.7588H158.186V26.7178H156.019V26.0239H158.186V24.9829H159.63V26.0239H161.797Z" fill="#295271" />
                <path d="M161.797 34.3513V35.0452H159.63V36.0862H158.186V35.0452H156.019V34.3513H158.186V33.3103H159.63V34.3513H161.797Z" fill="#295271" />
                <path d="M161.797 42.6786V43.3726H159.63V44.4136H158.186V43.3726H156.019V42.6786H158.186V41.6377H159.63V42.6786H161.797Z" fill="#295271" />
                <path d="M161.797 51.0068V51.7007H159.63V52.7417H158.186V51.7007H156.019V51.0068H158.186V49.9658H159.63V51.0068H161.797Z" fill="#295271" />
                <path d="M161.797 59.3342V60.0281H159.63V61.0691H158.186V60.0281H156.019V59.3342H158.186V58.2932H159.63V59.3342H161.797Z" fill="#295271" />
                <path d="M161.797 67.6616V68.3555H159.63V69.3965H158.186V68.3555H156.019V67.6616H158.186V66.6206H159.63V67.6616H161.797Z" fill="#295271" />
                <path d="M161.797 75.9892V76.6832H159.63V77.7241H158.186V76.6832H156.019V75.9892H158.186V74.9482H159.63V75.9892H161.797Z" fill="#295271" />
                <path d="M161.797 84.3166V85.0106H159.63V86.0515H158.186V85.0106H156.019V84.3166H158.186V83.2756H159.63V84.3166H161.797Z" fill="#295271" />
                <path d="M161.797 92.6445V93.3384H159.63V94.3794H158.186V93.3384H156.019V92.6445H158.186V91.6035H159.63V92.6445H161.797Z" fill="#295271" />
                <path d="M161.797 100.972V101.666H159.63V102.707H158.186V101.666H156.019V100.972H158.186V99.9309H159.63V100.972H161.797Z" fill="#295271" />
                <path d="M161.797 109.299V109.993H159.63V111.034H158.186V109.993H156.019V109.299H158.186V108.259H159.63V109.299H161.797Z" fill="#295271" />
                <path d="M161.797 117.627V118.321H159.63V119.362H158.186V118.321H156.019V117.627H158.186V116.586H159.63V117.627H161.797Z" fill="#295271" />
                <path d="M161.797 125.955V126.649H159.63V127.69H158.186V126.649H156.019V125.955H158.186V124.914H159.63V125.955H161.797Z" fill="#295271" />
                <path d="M161.797 134.282V134.976H159.63V136.017H158.186V134.976H156.019V134.282H158.186V133.241H159.63V134.282H161.797Z" fill="#295271" />
                <path d="M161.797 142.61V143.304H159.63V144.345H158.186V143.304H156.019V142.61H158.186V141.569H159.63V142.61H161.797Z" fill="#295271" />
                <path d="M161.797 150.937V151.631H159.63V152.672H158.186V151.631H156.019V150.937H158.186V149.896H159.63V150.937H161.797Z" fill="#295271" />
                <path d="M161.797 159.265V159.959H159.63V161H158.186V159.959H156.019V159.265H158.186V158.224H159.63V159.265H161.797Z" fill="#295271" />
                <path d="M144.462 1.04095V1.73491H142.295V2.77586H140.85V1.73491H138.683V1.04095H140.85V0H142.295V1.04095H144.462Z" fill="#295271" />
                <path d="M144.462 9.36859V10.0626H142.295V11.1035H140.85V10.0626H138.683V9.36859H140.85V8.32764H142.295V9.36859H144.462Z" fill="#295271" />
                <path d="M144.462 17.6962V18.3902H142.295V19.4311H140.85V18.3902H138.683V17.6962H140.85V16.6553H142.295V17.6962H144.462Z" fill="#295271" />
                <path d="M144.462 26.0239V26.7178H142.295V27.7588H140.85V26.7178H138.683V26.0239H140.85V24.9829H142.295V26.0239H144.462Z" fill="#295271" />
                <path d="M144.462 34.3513V35.0452H142.295V36.0862H140.85V35.0452H138.683V34.3513H140.85V33.3103H142.295V34.3513H144.462Z" fill="#295271" />
                <path d="M144.462 42.6786V43.3726H142.295V44.4136H140.85V43.3726H138.683V42.6786H140.85V41.6377H142.295V42.6786H144.462Z" fill="#295271" />
                <path d="M144.462 51.0068V51.7007H142.295V52.7417H140.85V51.7007H138.683V51.0068H140.85V49.9658H142.295V51.0068H144.462Z" fill="#295271" />
                <path d="M144.462 59.3342V60.0281H142.295V61.0691H140.85V60.0281H138.683V59.3342H140.85V58.2932H142.295V59.3342H144.462Z" fill="#295271" />
                <path d="M144.462 67.6616V68.3555H142.295V69.3965H140.85V68.3555H138.683V67.6616H140.85V66.6206H142.295V67.6616H144.462Z" fill="#295271" />
                <path d="M144.462 75.9892V76.6832H142.295V77.7241H140.85V76.6832H138.683V75.9892H140.85V74.9482H142.295V75.9892H144.462Z" fill="#295271" />
                <path d="M144.462 84.3166V85.0106H142.295V86.0515H140.85V85.0106H138.683V84.3166H140.85V83.2756H142.295V84.3166H144.462Z" fill="#295271" />
                <path d="M144.462 92.6445V93.3384H142.295V94.3794H140.85V93.3384H138.683V92.6445H140.85V91.6035H142.295V92.6445H144.462Z" fill="#295271" />
                <path d="M144.462 100.972V101.666H142.295V102.707H140.85V101.666H138.683V100.972H140.85V99.9309H142.295V100.972H144.462Z" fill="#295271" />
                <path d="M144.462 109.299V109.993H142.295V111.034H140.85V109.993H138.683V109.299H140.85V108.259H142.295V109.299H144.462Z" fill="#295271" />
                <path d="M144.462 117.627V118.321H142.295V119.362H140.85V118.321H138.683V117.627H140.85V116.586H142.295V117.627H144.462Z" fill="#295271" />
                <path d="M144.462 125.955V126.649H142.295V127.69H140.85V126.649H138.683V125.955H140.85V124.914H142.295V125.955H144.462Z" fill="#295271" />
                <path d="M144.462 134.282V134.976H142.295V136.017H140.85V134.976H138.683V134.282H140.85V133.241H142.295V134.282H144.462Z" fill="#295271" />
                <path d="M144.462 142.61V143.304H142.295V144.345H140.85V143.304H138.683V142.61H140.85V141.569H142.295V142.61H144.462Z" fill="#295271" />
                <path d="M144.462 150.937V151.631H142.295V152.672H140.85V151.631H138.683V150.937H140.85V149.896H142.295V150.937H144.462Z" fill="#295271" />
                <path d="M144.462 159.265V159.959H142.295V161H140.85V159.959H138.683V159.265H140.85V158.224H142.295V159.265H144.462Z" fill="#295271" />
                <path d="M127.126 1.04095V1.73491H124.96V2.77586H123.515V1.73491H121.348V1.04095H123.515V0H124.96V1.04095H127.126Z" fill="#295271" />
                <path d="M127.126 9.36859V10.0626H124.959V11.1035H123.515V10.0626H121.348V9.36859H123.515V8.32764H124.959V9.36859H127.126Z" fill="#295271" />
                <path d="M127.126 17.6962V18.3902H124.959V19.4311H123.515V18.3902H121.348V17.6962H123.515V16.6553H124.959V17.6962H127.126Z" fill="#295271" />
                <path d="M127.126 26.0239V26.7178H124.959V27.7588H123.515V26.7178H121.348V26.0239H123.515V24.9829H124.959V26.0239H127.126Z" fill="#295271" />
                <path d="M127.126 34.3513V35.0452H124.959V36.0862H123.515V35.0452H121.348V34.3513H123.515V33.3103H124.959V34.3513H127.126Z" fill="#295271" />
                <path d="M127.126 42.6786V43.3726H124.959V44.4136H123.515V43.3726H121.348V42.6786H123.515V41.6377H124.959V42.6786H127.126Z" fill="#295271" />
                <path d="M127.126 51.0068V51.7007H124.959V52.7417H123.515V51.7007H121.348V51.0068H123.515V49.9658H124.959V51.0068H127.126Z" fill="#295271" />
                <path d="M127.126 59.3342V60.0281H124.959V61.0691H123.515V60.0281H121.348V59.3342H123.515V58.2932H124.959V59.3342H127.126Z" fill="#295271" />
                <path d="M127.126 67.6616V68.3555H124.959V69.3965H123.515V68.3555H121.348V67.6616H123.515V66.6206H124.959V67.6616H127.126Z" fill="#295271" />
                <path d="M127.126 75.9892V76.6832H124.959V77.7241H123.515V76.6832H121.348V75.9892H123.515V74.9482H124.959V75.9892H127.126Z" fill="#295271" />
                <path d="M127.126 84.3166V85.0106H124.959V86.0515H123.515V85.0106H121.348V84.3166H123.515V83.2756H124.959V84.3166H127.126Z" fill="#295271" />
                <path d="M127.126 92.6445V93.3384H124.959V94.3794H123.515V93.3384H121.348V92.6445H123.515V91.6035H124.959V92.6445H127.126Z" fill="#295271" />
                <path d="M127.126 100.972V101.666H124.959V102.707H123.515V101.666H121.348V100.972H123.515V99.9309H124.959V100.972H127.126Z" fill="#295271" />
                <path d="M127.126 109.299V109.993H124.959V111.034H123.515V109.993H121.348V109.299H123.515V108.259H124.959V109.299H127.126Z" fill="#295271" />
                <path d="M127.126 117.627V118.321H124.959V119.362H123.515V118.321H121.348V117.627H123.515V116.586H124.959V117.627H127.126Z" fill="#295271" />
                <path d="M127.126 125.955V126.649H124.959V127.69H123.515V126.649H121.348V125.955H123.515V124.914H124.959V125.955H127.126Z" fill="#295271" />
                <path d="M127.126 134.282V134.976H124.959V136.017H123.515V134.976H121.348V134.282H123.515V133.241H124.959V134.282H127.126Z" fill="#295271" />
                <path d="M127.126 142.61V143.304H124.959V144.345H123.515V143.304H121.348V142.61H123.515V141.569H124.959V142.61H127.126Z" fill="#295271" />
                <path d="M127.126 150.937V151.631H124.959V152.672H123.515V151.631H121.348V150.937H123.515V149.896H124.959V150.937H127.126Z" fill="#295271" />
                <path d="M127.126 159.265V159.959H124.959V161H123.515V159.959H121.348V159.265H123.515V158.224H124.959V159.265H127.126Z" fill="#295271" />
                <path d="M109.791 1.04095V1.73491H107.624V2.77586H106.179V1.73491H104.012V1.04095H106.179V0H107.624V1.04095H109.791Z" fill="#295271" />
                <path d="M109.791 9.36859V10.0626H107.624V11.1035H106.179V10.0626H104.012V9.36859H106.179V8.32764H107.624V9.36859H109.791Z" fill="#295271" />
                <path d="M109.791 17.6962V18.3902H107.624V19.4311H106.179V18.3902H104.012V17.6962H106.179V16.6553H107.624V17.6962H109.791Z" fill="#295271" />
                <path d="M109.791 26.0239V26.7178H107.624V27.7588H106.179V26.7178H104.012V26.0239H106.179V24.9829H107.624V26.0239H109.791Z" fill="#295271" />
                <path d="M109.791 34.3513V35.0452H107.624V36.0862H106.179V35.0452H104.012V34.3513H106.179V33.3103H107.624V34.3513H109.791Z" fill="#295271" />
                <path d="M109.791 42.6786V43.3726H107.624V44.4136H106.179V43.3726H104.012V42.6786H106.179V41.6377H107.624V42.6786H109.791Z" fill="#295271" />
                <path d="M109.791 51.0068V51.7007H107.624V52.7417H106.179V51.7007H104.012V51.0068H106.179V49.9658H107.624V51.0068H109.791Z" fill="#295271" />
                <path d="M109.791 59.3342V60.0281H107.624V61.0691H106.179V60.0281H104.012V59.3342H106.179V58.2932H107.624V59.3342H109.791Z" fill="#295271" />
                <path d="M109.791 67.6616V68.3555H107.624V69.3965H106.179V68.3555H104.012V67.6616H106.179V66.6206H107.624V67.6616H109.791Z" fill="#295271" />
                <path d="M109.791 75.9892V76.6832H107.624V77.7241H106.179V76.6832H104.012V75.9892H106.179V74.9482H107.624V75.9892H109.791Z" fill="#295271" />
                <path d="M109.791 84.3166V85.0106H107.624V86.0515H106.179V85.0106H104.012V84.3166H106.179V83.2756H107.624V84.3166H109.791Z" fill="#295271" />
                <path d="M109.791 92.6445V93.3384H107.624V94.3794H106.179V93.3384H104.012V92.6445H106.179V91.6035H107.624V92.6445H109.791Z" fill="#295271" />
                <path d="M109.791 100.972V101.666H107.624V102.707H106.179V101.666H104.012V100.972H106.179V99.9309H107.624V100.972H109.791Z" fill="#295271" />
                <path d="M109.791 109.299V109.993H107.624V111.034H106.179V109.993H104.012V109.299H106.179V108.259H107.624V109.299H109.791Z" fill="#295271" />
                <path d="M109.791 117.627V118.321H107.624V119.362H106.179V118.321H104.012V117.627H106.179V116.586H107.624V117.627H109.791Z" fill="#295271" />
                <path d="M109.791 125.955V126.649H107.624V127.69H106.179V126.649H104.012V125.955H106.179V124.914H107.624V125.955H109.791Z" fill="#295271" />
                <path d="M109.791 134.282V134.976H107.624V136.017H106.179V134.976H104.012V134.282H106.179V133.241H107.624V134.282H109.791Z" fill="#295271" />
                <path d="M109.791 142.61V143.304H107.624V144.345H106.179V143.304H104.012V142.61H106.179V141.569H107.624V142.61H109.791Z" fill="#295271" />
                <path d="M109.791 150.937V151.631H107.624V152.672H106.179V151.631H104.012V150.937H106.179V149.896H107.624V150.937H109.791Z" fill="#295271" />
                <path d="M109.791 159.265V159.959H107.624V161H106.179V159.959H104.012V159.265H106.179V158.224H107.624V159.265H109.791Z" fill="#295271" />
                <path d="M92.4556 1.04095V1.73491H90.2887V2.77586H88.8441V1.73491H86.6771V1.04095H88.8441V0H90.2887V1.04095H92.4556Z" fill="#295271" />
                <path d="M92.4555 9.36859V10.0626H90.2886V11.1035H88.8439V10.0626H86.677V9.36859H88.8439V8.32764H90.2886V9.36859H92.4555Z" fill="#295271" />
                <path d="M92.4555 17.6962V18.3902H90.2886V19.4311H88.8439V18.3902H86.677V17.6962H88.8439V16.6553H90.2886V17.6962H92.4555Z" fill="#295271" />
                <path d="M92.4555 26.0239V26.7178H90.2886V27.7588H88.8439V26.7178H86.677V26.0239H88.8439V24.9829H90.2886V26.0239H92.4555Z" fill="#295271" />
                <path d="M92.4555 34.3513V35.0452H90.2886V36.0862H88.8439V35.0452H86.677V34.3513H88.8439V33.3103H90.2886V34.3513H92.4555Z" fill="#295271" />
                <path d="M92.4555 42.6786V43.3726H90.2886V44.4136H88.8439V43.3726H86.677V42.6786H88.8439V41.6377H90.2886V42.6786H92.4555Z" fill="#295271" />
                <path d="M92.4555 51.0068V51.7007H90.2886V52.7417H88.8439V51.7007H86.677V51.0068H88.8439V49.9658H90.2886V51.0068H92.4555Z" fill="#295271" />
                <path d="M92.4555 59.3342V60.0281H90.2886V61.0691H88.8439V60.0281H86.677V59.3342H88.8439V58.2932H90.2886V59.3342H92.4555Z" fill="#295271" />
                <path d="M92.4555 67.6616V68.3555H90.2886V69.3965H88.8439V68.3555H86.677V67.6616H88.8439V66.6206H90.2886V67.6616H92.4555Z" fill="#295271" />
                <path d="M92.4555 75.9892V76.6832H90.2886V77.7241H88.8439V76.6832H86.677V75.9892H88.8439V74.9482H90.2886V75.9892H92.4555Z" fill="#295271" />
                <path d="M92.4555 84.3166V85.0106H90.2886V86.0515H88.8439V85.0106H86.677V84.3166H88.8439V83.2756H90.2886V84.3166H92.4555Z" fill="#295271" />
                <path d="M92.4555 92.6445V93.3384H90.2886V94.3794H88.8439V93.3384H86.677V92.6445H88.8439V91.6035H90.2886V92.6445H92.4555Z" fill="#295271" />
                <path d="M92.4555 100.972V101.666H90.2886V102.707H88.8439V101.666H86.677V100.972H88.8439V99.9309H90.2886V100.972H92.4555Z" fill="#295271" />
                <path d="M92.4555 109.299V109.993H90.2886V111.034H88.8439V109.993H86.677V109.299H88.8439V108.259H90.2886V109.299H92.4555Z" fill="#295271" />
                <path d="M92.4555 117.627V118.321H90.2886V119.362H88.8439V118.321H86.677V117.627H88.8439V116.586H90.2886V117.627H92.4555Z" fill="#295271" />
                <path d="M92.4555 125.955V126.649H90.2886V127.69H88.8439V126.649H86.677V125.955H88.8439V124.914H90.2886V125.955H92.4555Z" fill="#295271" />
                <path d="M92.4555 134.282V134.976H90.2886V136.017H88.8439V134.976H86.677V134.282H88.8439V133.241H90.2886V134.282H92.4555Z" fill="#295271" />
                <path d="M92.4555 142.61V143.304H90.2886V144.345H88.8439V143.304H86.677V142.61H88.8439V141.569H90.2886V142.61H92.4555Z" fill="#295271" />
                <path d="M92.4555 150.937V151.631H90.2886V152.672H88.8439V151.631H86.677V150.937H88.8439V149.896H90.2886V150.937H92.4555Z" fill="#295271" />
                <path d="M92.4555 159.265V159.959H90.2886V161H88.8439V159.959H86.677V159.265H88.8439V158.224H90.2886V159.265H92.4555Z" fill="#295271" />
                <path d="M75.1202 1.04095V1.73491H72.9532V2.77586H71.5086V1.73491H69.3417V1.04095H71.5086V0H72.9532V1.04095H75.1202Z" fill="#295271" />
                <path d="M75.12 9.36859V10.0626H72.9531V11.1035H71.5085V10.0626H69.3416V9.36859H71.5085V8.32764H72.9531V9.36859H75.12Z" fill="#295271" />
                <path d="M75.12 17.6962V18.3902H72.9531V19.4311H71.5085V18.3902H69.3416V17.6962H71.5085V16.6553H72.9531V17.6962H75.12Z" fill="#295271" />
                <path d="M75.12 26.0239V26.7178H72.9531V27.7588H71.5085V26.7178H69.3416V26.0239H71.5085V24.9829H72.9531V26.0239H75.12Z" fill="#295271" />
                <path d="M75.12 34.3513V35.0452H72.9531V36.0862H71.5085V35.0452H69.3416V34.3513H71.5085V33.3103H72.9531V34.3513H75.12Z" fill="#295271" />
                <path d="M75.12 42.6786V43.3726H72.9531V44.4136H71.5085V43.3726H69.3416V42.6786H71.5085V41.6377H72.9531V42.6786H75.12Z" fill="#295271" />
                <path d="M75.12 51.0068V51.7007H72.9531V52.7417H71.5085V51.7007H69.3416V51.0068H71.5085V49.9658H72.9531V51.0068H75.12Z" fill="#295271" />
                <path d="M75.12 59.3342V60.0281H72.9531V61.0691H71.5085V60.0281H69.3416V59.3342H71.5085V58.2932H72.9531V59.3342H75.12Z" fill="#295271" />
                <path d="M75.12 67.6616V68.3555H72.9531V69.3965H71.5085V68.3555H69.3416V67.6616H71.5085V66.6206H72.9531V67.6616H75.12Z" fill="#295271" />
                <path d="M75.12 75.9892V76.6832H72.9531V77.7241H71.5085V76.6832H69.3416V75.9892H71.5085V74.9482H72.9531V75.9892H75.12Z" fill="#295271" />
                <path d="M75.12 84.3166V85.0106H72.9531V86.0515H71.5085V85.0106H69.3416V84.3166H71.5085V83.2756H72.9531V84.3166H75.12Z" fill="#295271" />
                <path d="M75.12 92.6445V93.3384H72.9531V94.3794H71.5085V93.3384H69.3416V92.6445H71.5085V91.6035H72.9531V92.6445H75.12Z" fill="#295271" />
                <path d="M75.12 100.972V101.666H72.9531V102.707H71.5085V101.666H69.3416V100.972H71.5085V99.9309H72.9531V100.972H75.12Z" fill="#295271" />
                <path d="M75.12 109.299V109.993H72.9531V111.034H71.5085V109.993H69.3416V109.299H71.5085V108.259H72.9531V109.299H75.12Z" fill="#295271" />
                <path d="M75.12 117.627V118.321H72.9531V119.362H71.5085V118.321H69.3416V117.627H71.5085V116.586H72.9531V117.627H75.12Z" fill="#295271" />
                <path d="M75.12 125.955V126.649H72.9531V127.69H71.5085V126.649H69.3416V125.955H71.5085V124.914H72.9531V125.955H75.12Z" fill="#295271" />
                <path d="M75.12 134.282V134.976H72.9531V136.017H71.5085V134.976H69.3416V134.282H71.5085V133.241H72.9531V134.282H75.12Z" fill="#295271" />
                <path d="M75.12 142.61V143.304H72.9531V144.345H71.5085V143.304H69.3416V142.61H71.5085V141.569H72.9531V142.61H75.12Z" fill="#295271" />
                <path d="M75.12 150.937V151.631H72.9531V152.672H71.5085V151.631H69.3416V150.937H71.5085V149.896H72.9531V150.937H75.12Z" fill="#295271" />
                <path d="M75.12 159.265V159.959H72.9531V161H71.5085V159.959H69.3416V159.265H71.5085V158.224H72.9531V159.265H75.12Z" fill="#295271" />
                <path d="M57.7847 1.04095V1.73491H55.6177V2.77586H54.1731V1.73491H52.0062V1.04095H54.1731V0H55.6177V1.04095H57.7847Z" fill="#295271" />
                <path d="M57.7846 9.36859V10.0626H55.6177V11.1035H54.173V10.0626H52.0061V9.36859H54.173V8.32764H55.6177V9.36859H57.7846Z" fill="#295271" />
                <path d="M57.7846 17.6962V18.3902H55.6177V19.4311H54.173V18.3902H52.0061V17.6962H54.173V16.6553H55.6177V17.6962H57.7846Z" fill="#295271" />
                <path d="M57.7846 26.0239V26.7178H55.6177V27.7588H54.173V26.7178H52.0061V26.0239H54.173V24.9829H55.6177V26.0239H57.7846Z" fill="#295271" />
                <path d="M57.7846 34.3513V35.0452H55.6177V36.0862H54.173V35.0452H52.0061V34.3513H54.173V33.3103H55.6177V34.3513H57.7846Z" fill="#295271" />
                <path d="M57.7846 42.6786V43.3726H55.6177V44.4136H54.173V43.3726H52.0061V42.6786H54.173V41.6377H55.6177V42.6786H57.7846Z" fill="#295271" />
                <path d="M57.7846 51.0068V51.7007H55.6177V52.7417H54.173V51.7007H52.0061V51.0068H54.173V49.9658H55.6177V51.0068H57.7846Z" fill="#295271" />
                <path d="M57.7846 59.3342V60.0281H55.6177V61.0691H54.173V60.0281H52.0061V59.3342H54.173V58.2932H55.6177V59.3342H57.7846Z" fill="#295271" />
                <path d="M57.7846 67.6616V68.3555H55.6177V69.3965H54.173V68.3555H52.0061V67.6616H54.173V66.6206H55.6177V67.6616H57.7846Z" fill="#295271" />
                <path d="M57.7846 75.9892V76.6832H55.6177V77.7241H54.173V76.6832H52.0061V75.9892H54.173V74.9482H55.6177V75.9892H57.7846Z" fill="#295271" />
                <path d="M57.7846 84.3166V85.0106H55.6177V86.0515H54.173V85.0106H52.0061V84.3166H54.173V83.2756H55.6177V84.3166H57.7846Z" fill="#295271" />
                <path d="M57.7846 92.6445V93.3384H55.6177V94.3794H54.173V93.3384H52.0061V92.6445H54.173V91.6035H55.6177V92.6445H57.7846Z" fill="#295271" />
                <path d="M57.7846 100.972V101.666H55.6177V102.707H54.173V101.666H52.0061V100.972H54.173V99.9309H55.6177V100.972H57.7846Z" fill="#295271" />
                <path d="M57.7846 109.299V109.993H55.6177V111.034H54.173V109.993H52.0061V109.299H54.173V108.259H55.6177V109.299H57.7846Z" fill="#295271" />
                <path d="M57.7846 117.627V118.321H55.6177V119.362H54.173V118.321H52.0061V117.627H54.173V116.586H55.6177V117.627H57.7846Z" fill="#295271" />
                <path d="M57.7846 125.955V126.649H55.6177V127.69H54.173V126.649H52.0061V125.955H54.173V124.914H55.6177V125.955H57.7846Z" fill="#295271" />
                <path d="M57.7846 134.282V134.976H55.6177V136.017H54.173V134.976H52.0061V134.282H54.173V133.241H55.6177V134.282H57.7846Z" fill="#295271" />
                <path d="M57.7846 142.61V143.304H55.6177V144.345H54.173V143.304H52.0061V142.61H54.173V141.569H55.6177V142.61H57.7846Z" fill="#295271" />
                <path d="M57.7846 150.937V151.631H55.6177V152.672H54.173V151.631H52.0061V150.937H54.173V149.896H55.6177V150.937H57.7846Z" fill="#295271" />
                <path d="M57.7846 159.265V159.959H55.6177V161H54.173V159.959H52.0061V159.265H54.173V158.224H55.6177V159.265H57.7846Z" fill="#295271" />
                <path d="M40.4494 1.04095V1.73491H38.2824V2.77586H36.8378V1.73491H34.6709V1.04095H36.8378V0H38.2824V1.04095H40.4494Z" fill="#295271" />
                <path d="M40.4494 9.36859V10.0626H38.2824V11.1035H36.8378V10.0626H34.6709V9.36859H36.8378V8.32764H38.2824V9.36859H40.4494Z" fill="#295271" />
                <path d="M40.4494 17.6962V18.3902H38.2824V19.4311H36.8378V18.3902H34.6709V17.6962H36.8378V16.6553H38.2824V17.6962H40.4494Z" fill="#295271" />
                <path d="M40.4494 26.0239V26.7178H38.2824V27.7588H36.8378V26.7178H34.6709V26.0239H36.8378V24.9829H38.2824V26.0239H40.4494Z" fill="#295271" />
                <path d="M40.4494 34.3513V35.0452H38.2824V36.0862H36.8378V35.0452H34.6709V34.3513H36.8378V33.3103H38.2824V34.3513H40.4494Z" fill="#295271" />
                <path d="M40.4494 42.6786V43.3726H38.2824V44.4136H36.8378V43.3726H34.6709V42.6786H36.8378V41.6377H38.2824V42.6786H40.4494Z" fill="#295271" />
                <path d="M40.4494 51.0068V51.7007H38.2824V52.7417H36.8378V51.7007H34.6709V51.0068H36.8378V49.9658H38.2824V51.0068H40.4494Z" fill="#295271" />
                <path d="M40.4494 59.3342V60.0281H38.2824V61.0691H36.8378V60.0281H34.6709V59.3342H36.8378V58.2932H38.2824V59.3342H40.4494Z" fill="#295271" />
                <path d="M40.4494 67.6616V68.3555H38.2824V69.3965H36.8378V68.3555H34.6709V67.6616H36.8378V66.6206H38.2824V67.6616H40.4494Z" fill="#295271" />
                <path d="M40.4494 75.9892V76.6832H38.2824V77.7241H36.8378V76.6832H34.6709V75.9892H36.8378V74.9482H38.2824V75.9892H40.4494Z" fill="#295271" />
                <path d="M40.4494 84.3166V85.0106H38.2824V86.0515H36.8378V85.0106H34.6709V84.3166H36.8378V83.2756H38.2824V84.3166H40.4494Z" fill="#295271" />
                <path d="M40.4494 92.6445V93.3384H38.2824V94.3794H36.8378V93.3384H34.6709V92.6445H36.8378V91.6035H38.2824V92.6445H40.4494Z" fill="#295271" />
                <path d="M40.4494 100.972V101.666H38.2824V102.707H36.8378V101.666H34.6709V100.972H36.8378V99.9309H38.2824V100.972H40.4494Z" fill="#295271" />
                <path d="M40.4494 109.299V109.993H38.2824V111.034H36.8378V109.993H34.6709V109.299H36.8378V108.259H38.2824V109.299H40.4494Z" fill="#295271" />
                <path d="M40.4494 117.627V118.321H38.2824V119.362H36.8378V118.321H34.6709V117.627H36.8378V116.586H38.2824V117.627H40.4494Z" fill="#295271" />
                <path d="M40.4494 125.955V126.649H38.2824V127.69H36.8378V126.649H34.6709V125.955H36.8378V124.914H38.2824V125.955H40.4494Z" fill="#295271" />
                <path d="M40.4494 134.282V134.976H38.2824V136.017H36.8378V134.976H34.6709V134.282H36.8378V133.241H38.2824V134.282H40.4494Z" fill="#295271" />
                <path d="M40.4494 142.61V143.304H38.2824V144.345H36.8378V143.304H34.6709V142.61H36.8378V141.569H38.2824V142.61H40.4494Z" fill="#295271" />
                <path d="M40.4494 150.937V151.631H38.2824V152.672H36.8378V151.631H34.6709V150.937H36.8378V149.896H38.2824V150.937H40.4494Z" fill="#295271" />
                <path d="M40.4494 159.265V159.959H38.2824V161H36.8378V159.959H34.6709V159.265H36.8378V158.224H38.2824V159.265H40.4494Z" fill="#295271" />
                <path d="M23.1139 1.04095V1.73491H20.947V2.77586H19.5024V1.73491H17.3354V1.04095H19.5024V0H20.947V1.04095H23.1139Z" fill="#295271" />
                <path d="M23.1139 9.36859V10.0626H20.947V11.1035H19.5024V10.0626H17.3354V9.36859H19.5024V8.32764H20.947V9.36859H23.1139Z" fill="#295271" />
                <path d="M23.1139 17.6962V18.3902H20.947V19.4311H19.5024V18.3902H17.3354V17.6962H19.5024V16.6553H20.947V17.6962H23.1139Z" fill="#295271" />
                <path d="M23.1139 26.0239V26.7178H20.947V27.7588H19.5024V26.7178H17.3354V26.0239H19.5024V24.9829H20.947V26.0239H23.1139Z" fill="#295271" />
                <path d="M23.1139 34.3513V35.0452H20.947V36.0862H19.5024V35.0452H17.3354V34.3513H19.5024V33.3103H20.947V34.3513H23.1139Z" fill="#295271" />
                <path d="M23.1139 42.6786V43.3726H20.947V44.4136H19.5024V43.3726H17.3354V42.6786H19.5024V41.6377H20.947V42.6786H23.1139Z" fill="#295271" />
                <path d="M23.1139 51.0068V51.7007H20.947V52.7417H19.5024V51.7007H17.3354V51.0068H19.5024V49.9658H20.947V51.0068H23.1139Z" fill="#295271" />
                <path d="M23.1139 59.3342V60.0281H20.947V61.0691H19.5024V60.0281H17.3354V59.3342H19.5024V58.2932H20.947V59.3342H23.1139Z" fill="#295271" />
                <path d="M23.1139 67.6616V68.3555H20.947V69.3965H19.5024V68.3555H17.3354V67.6616H19.5024V66.6206H20.947V67.6616H23.1139Z" fill="#295271" />
                <path d="M23.1139 75.9892V76.6832H20.947V77.7241H19.5024V76.6832H17.3354V75.9892H19.5024V74.9482H20.947V75.9892H23.1139Z" fill="#295271" />
                <path d="M23.1139 84.3166V85.0106H20.947V86.0515H19.5024V85.0106H17.3354V84.3166H19.5024V83.2756H20.947V84.3166H23.1139Z" fill="#295271" />
                <path d="M23.1139 92.6445V93.3384H20.947V94.3794H19.5024V93.3384H17.3354V92.6445H19.5024V91.6035H20.947V92.6445H23.1139Z" fill="#295271" />
                <path d="M23.1139 100.972V101.666H20.947V102.707H19.5024V101.666H17.3354V100.972H19.5024V99.9309H20.947V100.972H23.1139Z" fill="#295271" />
                <path d="M23.1139 109.299V109.993H20.947V111.034H19.5024V109.993H17.3354V109.299H19.5024V108.259H20.947V109.299H23.1139Z" fill="#295271" />
                <path d="M23.1139 117.627V118.321H20.947V119.362H19.5024V118.321H17.3354V117.627H19.5024V116.586H20.947V117.627H23.1139Z" fill="#295271" />
                <path d="M23.1139 125.955V126.649H20.947V127.69H19.5024V126.649H17.3354V125.955H19.5024V124.914H20.947V125.955H23.1139Z" fill="#295271" />
                <path d="M23.1139 134.282V134.976H20.947V136.017H19.5024V134.976H17.3354V134.282H19.5024V133.241H20.947V134.282H23.1139Z" fill="#295271" />
                <path d="M23.1139 142.61V143.304H20.947V144.345H19.5024V143.304H17.3354V142.61H19.5024V141.569H20.947V142.61H23.1139Z" fill="#295271" />
                <path d="M23.1139 150.937V151.631H20.947V152.672H19.5024V151.631H17.3354V150.937H19.5024V149.896H20.947V150.937H23.1139Z" fill="#295271" />
                <path d="M23.1139 159.265V159.959H20.947V161H19.5024V159.959H17.3354V159.265H19.5024V158.224H20.947V159.265H23.1139Z" fill="#295271" />
                <path d="M5.77848 1.04095V1.73491H3.61155V2.77586H2.16693V1.73491H0V1.04095H2.16693V0H3.61155V1.04095H5.77848Z" fill="#295271" />
                <path d="M5.77848 9.36859V10.0626H3.61155V11.1035H2.16693V10.0626H0V9.36859H2.16693V8.32764H3.61155V9.36859H5.77848Z" fill="#295271" />
                <path d="M5.77848 17.6962V18.3902H3.61155V19.4311H2.16693V18.3902H0V17.6962H2.16693V16.6553H3.61155V17.6962H5.77848Z" fill="#295271" />
                <path d="M5.77848 26.0239V26.7178H3.61155V27.7588H2.16693V26.7178H0V26.0239H2.16693V24.9829H3.61155V26.0239H5.77848Z" fill="#295271" />
                <path d="M5.77848 34.3513V35.0452H3.61155V36.0862H2.16693V35.0452H0V34.3513H2.16693V33.3103H3.61155V34.3513H5.77848Z" fill="#295271" />
                <path d="M5.77848 42.6786V43.3726H3.61155V44.4136H2.16693V43.3726H0V42.6786H2.16693V41.6377H3.61155V42.6786H5.77848Z" fill="#295271" />
                <path d="M5.77848 51.0068V51.7007H3.61155V52.7417H2.16693V51.7007H0V51.0068H2.16693V49.9658H3.61155V51.0068H5.77848Z" fill="#295271" />
                <path d="M5.77848 59.3342V60.0281H3.61155V61.0691H2.16693V60.0281H0V59.3342H2.16693V58.2932H3.61155V59.3342H5.77848Z" fill="#295271" />
                <path d="M5.77848 67.6616V68.3555H3.61155V69.3965H2.16693V68.3555H0V67.6616H2.16693V66.6206H3.61155V67.6616H5.77848Z" fill="#295271" />
                <path d="M5.77848 75.9892V76.6832H3.61155V77.7241H2.16693V76.6832H0V75.9892H2.16693V74.9482H3.61155V75.9892H5.77848Z" fill="#295271" />
                <path d="M5.77848 84.3166V85.0106H3.61155V86.0515H2.16693V85.0106H0V84.3166H2.16693V83.2756H3.61155V84.3166H5.77848Z" fill="#295271" />
                <path d="M5.77848 92.6445V93.3384H3.61155V94.3794H2.16693V93.3384H0V92.6445H2.16693V91.6035H3.61155V92.6445H5.77848Z" fill="#295271" />
                <path d="M5.77848 100.972V101.666H3.61155V102.707H2.16693V101.666H0V100.972H2.16693V99.9309H3.61155V100.972H5.77848Z" fill="#295271" />
                <path d="M5.77848 109.299V109.993H3.61155V111.034H2.16693V109.993H0V109.299H2.16693V108.259H3.61155V109.299H5.77848Z" fill="#295271" />
                <path d="M5.77848 117.627V118.321H3.61155V119.362H2.16693V118.321H0V117.627H2.16693V116.586H3.61155V117.627H5.77848Z" fill="#295271" />
                <path d="M5.77848 125.955V126.649H3.61155V127.69H2.16693V126.649H0V125.955H2.16693V124.914H3.61155V125.955H5.77848Z" fill="#295271" />
                <path d="M5.77848 134.282V134.976H3.61155V136.017H2.16693V134.976H0V134.282H2.16693V133.241H3.61155V134.282H5.77848Z" fill="#295271" />
                <path d="M5.77848 142.61V143.304H3.61155V144.345H2.16693V143.304H0V142.61H2.16693V141.569H3.61155V142.61H5.77848Z" fill="#295271" />
                <path d="M5.77848 150.937V151.631H3.61155V152.672H2.16693V151.631H0V150.937H2.16693V149.896H3.61155V150.937H5.77848Z" fill="#295271" />
                <path d="M5.77848 159.265V159.959H3.61155V161H2.16693V159.959H0V159.265H2.16693V158.224H3.61155V159.265H5.77848Z" fill="#295271" />
            </g>
        </svg>
    )
}

export default DotPattern;