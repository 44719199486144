import { useEffect, useState } from "react";
import {
  Grid3,
  ChartCircle,
  ProfileCircle,
  Data2,
  Calculator,
  DocumentText1,
} from "iconsax-react";
import NavigationButton from "./utils/button";
import { NavLink, useLocation } from "react-router-dom";
import NavigationSubButton from "./utils/subbutton";
import NaviVector from "../utils/NaviVector";

function Navigation() {
  const location = useLocation();
  const [path, setPath] = useState("");
  const [isNaviExpand, setIsNaviExpand] = useState(true);
  const [subMenuExpand, setSubMenuExpand] = useState(false);
  const [menuExpand, setMenuExpand] = useState();

  function expandClicked() {
    setIsNaviExpand((current) => !current);
    setMenuExpand(isNaviExpand)
    if (isNaviExpand) setSubMenuExpand(false);
  }
  useEffect(() => {
    setPath(location.pathname);
    if (location.pathname.split("/")[1] === "mscc")
      setMenuExpand("mscc");
    else
      setMenuExpand("");
  }, [location]);

  return (
    <div className="h-screen">
      <div
        className={
          "h-full py-[12px] bg-neutral-1 relative flex flex-col justify-between " +
          (isNaviExpand ? "w-[240px]" : "w-[80px]")
        }
      >
        <div className="mb-10 h-auto">
          ICON
        </div>

        <div className="flex flex-col flex-initial h-full overflow-y-auto">
          <NavLink to="/">
            <NavigationButton isNaviExpand={isNaviExpand} link={"/"}>
              <Grid3 variant="Bold" />
              Хянах самбар
            </NavigationButton>
          </NavLink>
          <NavLink to="investors">
            <NavigationButton isNaviExpand={isNaviExpand} link={"investors"}>
              <ChartCircle />
              Хөрөнгө оруулалт
            </NavigationButton>
          </NavLink>
          <NavLink to="stocks">
            <NavigationButton isNaviExpand={isNaviExpand} link={"stocks"}>
              <ProfileCircle />
              Хувийн мэдээлэл
            </NavigationButton>
          </NavLink>
          <NavLink to="ipo">
            <NavigationButton isNaviExpand={isNaviExpand} link={"ipo"}>
              <Data2 />
              Сангууд
            </NavigationButton>
          </NavLink>
          <NavLink to="non-trading">
            <NavigationButton isNaviExpand={isNaviExpand} link={"non-trading"}>
              <Calculator />
              Тооцоолуур
            </NavigationButton>
          </NavLink>
          <NavLink to="non-trading">
            <NavigationButton isNaviExpand={isNaviExpand} link={"non-trading"}>
              <DocumentText1 />
              Мэдээ
            </NavigationButton>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
