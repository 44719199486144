import DarkBlue from "../../../../../component/utils/immunity/darkblue";
import Pink from "../../../../../component/utils/immunity/pink";
import Purple from "../../../../../component/utils/immunity/purple";
import Red from "../../../../../component/utils/immunity/red";
import SkyBlue from "../../../../../component/utils/immunity/skyblue";
import Yellow from "../../../../../component/utils/immunity/yellow";
import AdvantageInfo from "../../../utils/advantage-info";


function AdvantagesView() {

    return (
        <div className="flex flex-col justify-center items-center px-[228px] py-[100px] gap-[72px] bg-white">
            <div className="flex flex-col text-neutral-600 text-[24px] text-center">
                <div className="text-[32px] font-semibold">
                    Хөрөнгө оруулалтын сангийн давуу тал
                </div>
                Оновчтой үр дүнд хүрэх стратеги шийдэл
            </div>
            <div className="grid grid-cols-3 gap-16">
                <AdvantageInfo
                    icon={<SkyBlue />}
                    title="Өндөр өгөөж"
                    info="Таны оруулсан хөрөнгөөр сан нь гадаадын болон дотоодын өндөр өгөөжтэй хөрөнгийн зах зээлд хөрөнгө оруулалт хийдэг."
                />
                <AdvantageInfo
                    icon={<Red />}
                    title="Төрөлжүүлэлт"
                    info="Олон төрлийн үнэт цаасанд багцын хөрөнгө оруулалт хийснээр томоохон хөрөнгө оруулагчтай нэгэн адил өгөөж хүртэх, зардлаа х эмнэхийн зэрэгцээ эрсдэлийг тархаан байршуулж чаддаг."
                />
                <AdvantageInfo
                    icon={<Pink />}
                    title="Цаг бол алт"
                    info="Сан нь нарийн судалгаан дээр үндэслэн, оновчтой хөрөнгө оруулалтын аргыг ашиглан хөрөнгийг тань удирдах тул таны цагийг хэмнэнэ."
                />
                <AdvantageInfo
                    icon={<Purple />}
                    title="Бага татвар"
                    info="Хөрөнгө оруулалтын сангаас хуваарилах орлогоос та татварын давуу тал эдлэх бүрэн боломжтой."
                />
                <AdvantageInfo
                    icon={<Yellow />}
                    title="Мэргэжлийн баг"
                    info="Таны оруулсан хөрөнгийг мэдлэг туршлага бүхий мэргэжлийн хөрөнгө оруулагчид удирдах тул та заавал хөрөнгө оруулалтын талаар мэдлэг чадвартай байх албагүй."
                />
                <AdvantageInfo
                    icon={<DarkBlue />}
                    title="Хөрвөх чадвар"
                    info="Сангийн нэг төрөл болох хамтын хөрөнгө оруулалтын сангийн нэгж эрхийг хөрөнгийн зах зээл дээр нээлттэй арилжаалах боломжтой тул хөрвөх чадвар сайтай."
                />
            </div>
        </div>
    );
}

export default AdvantagesView;
