import { ArrowRight, ArrowRight2, Share } from "iconsax-react";

function NewsfeedSectionView() {

    return (
        <div className="flex flex-col px-[228px] py-[100px] gap-[72px]">
            <div className="flex flex-col text-[24px] text-center">
                <div className="font-bold text-[32px]">Мэдээ, мэдээлэл</div>
                Хөрөнгө оруулалтын багцтай холбоотой сүүлийн үеийн мэдээ мэдээлэл
            </div>
            <div className="flex flex-col gap-[48px]">
                <div className="grid grid-cols-4 gap-[24px] font-thin">
                    <div className="flex flex-col">
                        <div className="relative w-full h-[270px] bg-neutral-200 rounded-sm">
                            <div className="absolute bottom-0 w-full justify-between p-3 flex gap-[10px] text-[12px] text-white">
                                <div className="uppercase p-[6px] rounded-sm bg-primary">хос</div>
                                <div className="flex gap-[10px] items-center">
                                    <div className="text-end">2023 оны 1 сарын 25</div>
                                    <Share size={24} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px] text-justify text-[16px]">
                            <div className="font-bold text-[18px]">Хөрөнгө оруулагч сангын мэдээ.</div>
                            <div className="text-[#48464C] h-[96px] overflow-hidden">2023 оны 1 дүгээр сарын 25-ны өдөр энгийн арилжаагаар 45 хувьцаат компанийн 479,552,444 төгрөгийн үнийн дүн бүхий 1,369,435 ширхэг хувьцаа арилжаалагдсанаас 23 компанийн хувьцааны ханш өсөж, 17 компанийн хувьцааны ханш буурсан бол 5 компанийн ханш тогтвортой байлаа. Үүнээс "Жидакс" ХК (SOH +15.00%), "Э-Транс ложистикс" XК (ETR +12.49%) болон "Махимпекс" ХК (MMX +7.08%) зэрэг компаниудын хувьцааны ханш хамгийн өндөр өсөлттэй байсан бол "Увс чацаргана" ХК (CHR -13.85%), "Хөвсгөл алтан дуулга" ХК (ADU -7.21%) болон "Мерекс" ХК (MRX -7.14%)-ийн хувьцаа хамгийн их уналттай байлаа.</div>
                            <div className="flex items-center gap-[12px] font-medium">Дэлгэрэнгүй <ArrowRight2 size={10} /></div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="relative w-full h-[270px] bg-neutral-200 rounded-sm">
                            <div className="absolute bottom-0 w-full justify-between flex gap-[10px] text-[12px] text-white">
                                <div className="uppercase p-[6px] rounded-sm bg-primary">хос</div>
                                <div className="flex gap-[10px] items-center">
                                    <div className="text-end">2023 оны 1 сарын 25</div>
                                    <Share size={24} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px] text-justify text-[16px]">
                            <div className="font-bold text-[18px]">Хөрөнгө оруулагч сангын мэдээ.</div>
                            <div className="text-[#48464C] h-[96px] overflow-hidden">2023 оны 1 дүгээр сарын 25-ны өдөр энгийн арилжаагаар 45 хувьцаат компанийн 479,552,444 төгрөгийн үнийн дүн бүхий 1,369,435 ширхэг хувьцаа арилжаалагдсанаас 23 компанийн хувьцааны ханш өсөж, 17 компанийн хувьцааны ханш буурсан бол 5 компанийн ханш тогтвортой байлаа. Үүнээс "Жидакс" ХК (SOH +15.00%), "Э-Транс ложистикс" XК (ETR +12.49%) болон "Махимпекс" ХК (MMX +7.08%) зэрэг компаниудын хувьцааны ханш хамгийн өндөр өсөлттэй байсан бол "Увс чацаргана" ХК (CHR -13.85%), "Хөвсгөл алтан дуулга" ХК (ADU -7.21%) болон "Мерекс" ХК (MRX -7.14%)-ийн хувьцаа хамгийн их уналттай байлаа.</div>
                            <div className="flex items-center gap-[12px] font-medium">Дэлгэрэнгүй <ArrowRight2 size={10} /></div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="relative w-full h-[270px] bg-neutral-200 rounded-sm">
                            <div className="absolute bottom-0 w-full justify-between flex gap-[10px] text-[12px] text-white">
                                <div className="uppercase p-[6px] rounded-sm bg-primary">хос</div>
                                <div className="flex gap-[10px] items-center">
                                    <div className="text-end">2023 оны 1 сарын 25</div>
                                    <Share size={24} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px] text-justify text-[16px]">
                            <div className="font-bold text-[18px]">Хөрөнгө оруулагч сангын мэдээ.</div>
                            <div className="text-[#48464C] h-[96px] overflow-hidden">2023 оны 1 дүгээр сарын 25-ны өдөр энгийн арилжаагаар 45 хувьцаат компанийн 479,552,444 төгрөгийн үнийн дүн бүхий 1,369,435 ширхэг хувьцаа арилжаалагдсанаас 23 компанийн хувьцааны ханш өсөж, 17 компанийн хувьцааны ханш буурсан бол 5 компанийн ханш тогтвортой байлаа. Үүнээс "Жидакс" ХК (SOH +15.00%), "Э-Транс ложистикс" XК (ETR +12.49%) болон "Махимпекс" ХК (MMX +7.08%) зэрэг компаниудын хувьцааны ханш хамгийн өндөр өсөлттэй байсан бол "Увс чацаргана" ХК (CHR -13.85%), "Хөвсгөл алтан дуулга" ХК (ADU -7.21%) болон "Мерекс" ХК (MRX -7.14%)-ийн хувьцаа хамгийн их уналттай байлаа.</div>
                            <div className="flex items-center gap-[12px] font-medium">Дэлгэрэнгүй <ArrowRight2 size={10} /></div>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="relative w-full h-[270px] bg-neutral-200 rounded-sm">
                            <div className="absolute bottom-0 w-full justify-between flex gap-[10px] text-[12px] text-white">
                                <div className="uppercase p-[6px] rounded-sm bg-primary">хос</div>
                                <div className="flex gap-[10px] items-center">
                                    <div className="text-end">2023 оны 1 сарын 25</div>
                                    <Share size={24} />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[4px] text-justify text-[16px]">
                            <div className="font-bold text-[18px]">Хөрөнгө оруулагч сангын мэдээ.</div>
                            <div className="text-[#48464C] h-[96px] overflow-hidden">2023 оны 1 дүгээр сарын 25-ны өдөр энгийн арилжаагаар 45 хувьцаат компанийн 479,552,444 төгрөгийн үнийн дүн бүхий 1,369,435 ширхэг хувьцаа арилжаалагдсанаас 23 компанийн хувьцааны ханш өсөж, 17 компанийн хувьцааны ханш буурсан бол 5 компанийн ханш тогтвортой байлаа. Үүнээс "Жидакс" ХК (SOH +15.00%), "Э-Транс ложистикс" XК (ETR +12.49%) болон "Махимпекс" ХК (MMX +7.08%) зэрэг компаниудын хувьцааны ханш хамгийн өндөр өсөлттэй байсан бол "Увс чацаргана" ХК (CHR -13.85%), "Хөвсгөл алтан дуулга" ХК (ADU -7.21%) болон "Мерекс" ХК (MRX -7.14%)-ийн хувьцаа хамгийн их уналттай байлаа.</div>
                            <div className="flex items-center gap-[12px] font-medium">Дэлгэрэнгүй <ArrowRight2 size={10} /></div>
                        </div>
                    </div>
                </div>
                <div className="text-[14px] px-[24px] py-[10px] bg-primary rounded-[100px] text-white m-auto">Бүх мэдээг харах</div>
            </div>
        </div>
    );
}

export default NewsfeedSectionView;
