import { ArrowRight, ArrowRight2, ArrowRight3 } from "iconsax-react";
import KiteLeft from "../../../../../component/utils/KiteLeft";
import KiteRight from "../../../../../component/utils/KiteRight";
import HeroSectionSlider from "../../../utils/hero-section-slider";

function HeroSectionView() {

    return (
        <div className="flex flex-col px-[228px]">
            <div className="grid grid-cols-5 gap-3 text-neutral-500 divide-x my-20">
                <div className="col-span-3 text-7xl flex flex-wrap font-bold">
                    Хөрөнгө оруулалтын хүчээр тогтвортой
                    <div className="text-primary font-extrabold mr-2">ирээдүйг</div>
                    бүтээнэ.
                    <KiteLeft className="absolute left-0" />
                </div>
                <div className="col-span-2 flex flex-col gap-3 font-thin text-justify pl-3">
                    Бид үйлчлүүлэгчдийнхээ санхүүгийн хэрэгцээг хангахуйц ирээдүйд хурдацтай өсөн дэвших, үр ашиг өндөртэй үнэт цаас болон шинэ залуу төслүүдэд хөрөнгө оруулах зорилготойгоор хөрөнгө оруулалтын сангуудыг итгэмжлэн удирдах, зөвлөх үйлчилгээ үзүүлнэ.
                    <div className="grid grid-cols-2">
                        <div className="flex items-center justify-between border-2 border-primary p-2 px-3 uppercase rounded-full font-bold">
                            Хөрөнгө оруулах
                            <ArrowRight className="p-1 bg-primary rounded-full text-white" />
                        </div>
                    </div>
                    <KiteRight className="absolute right-0" />
                </div>
            </div>
            <HeroSectionSlider />
        </div>
    );
}

export default HeroSectionView;
