

function AdvantageInfo(props) {

    return (
        <div className="flex gap-5">
            <div className="min-h-[88px]">
                {props.icon}
            </div>
            <div className="flex flex-col gap-2 text-neutral-600 text-sm text-justify leading-5 tracking-widest font-thin">
                <div className="font-bold text-base">{props.title}</div>
                {props.info}
            </div>
        </div>
    );
}

export default AdvantageInfo;
