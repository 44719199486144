import { Call, Facebook, Location, MessageCircle } from "iconsax-react";
import WebIconWithTitle from "../../../../component/utils/IconTitle";


function FooterView(props) {

    return (
        <div className="flex flex-col px-[228px] pt-[100px] pb-10 gap-5 bg-[#202D3B] text-white">
            <div className="grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-3 pr-10">
                    <div className="text-2xl font-bold ">Бидэнтэй холбоо барих</div>
                    <div className="text-sm">Та цахим шуудангаа бидэнд илгээснээр бид танд сүүлийн үеийн мэдээ, мэдээллийг явуулах болно.</div>
                    <div className="grid grid-cols-6 gap-5">
                        <div className="col-span-4 bg-[#ffffff14] rounded-[4px] text-[14px] py-3 px-5 w-full">И-мэйл хаягаа оруулна уу.</div>
                        <div className="col-span-2 font-bold text-sm rounded-[4px] bg-primary px-[64px] text-[20px] text-center py-3 px-5">Илгээх</div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-10">
                    <div className="flex flex-col gap-5">
                        <div className="text-5">ХОЛБООС</div>
                        <div className="flex flex-col flex-1 gap-3 text-xs text-[#AEA9B1]">
                            <div>Нүүр</div>
                            <div>Хөрөнгө оруулалтын сан</div>
                            <div>Бидний тухай</div>
                            <div>Холбоо барих</div>
                            <div>Нэвтрэх/Бүртгүүлэх</div>
                            <div>Эрсдэлийн удирдлага</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 text-[20px]">
                        <div className="flex flex-col gap-[6px]">
                            <div className="flex gap-[6px]">
                                <MessageCircle className="text-primary" variant="Bold" size={30} />
                                И-Мэйл:</div>
                            <div className="text-[#CAC5CD] text-xs">info@ten-investment.com</div>
                        </div>
                        <div className="flex flex-col gap-[6px]">
                            <div className="flex gap-[6px]">
                                <Call className="rotate-[-90deg] text-primary" variant="Bold" size={30} />
                                Утас:</div>
                            <div className="flex gap-[6px] px-3 text-xs text-[#CAC5CD]">
                                <div>+(976) 9910-7444</div>
                                <div>+(976) 9904-4501</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[6px]">
                            <div className="flex gap-[6px]">
                                <Location className="text-primary" variant="Bold" size={30} />
                                Хаяг:</div>
                            <div className="text-xs text-[#CAC5CD] text-justify">Монгол улс, Улаанбаатар хот, Хан-Уул дүүрэг, 18-р хороо, Богд Жавзандамба гудамж, Эл Эс плаза, 401а тоот</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-between border-t border-primary pt-3">
                <div className="flex items-center gap-5 text-xs">
                    <WebIconWithTitle />
                    Copyright by 2019 Tenplus, Inc
                </div>
                <div className="grid grid-cols-4 gap-5">
                    <Facebook />
                    <Facebook />
                    <Facebook />
                    <Facebook />
                </div>
            </div>
        </div>
    );
}

export default FooterView;
