
function OurServiceView() {

    return (
        <div className="flex flex-col px-[352px] py-[100px] gap-[72px]">
            <div className="flex flex-col text-[24px] text-center">
                <div className="font-bold text-[32px]">Бидний үйлчилгээ</div>
                Хөрөнгө оруулалтын багцтай холбоотой таньд үзүүлэх бидний үйлчилгээ
            </div>
            <div className="grid grid-cols-2 gap-[48px] font-thin">
                <div className="flex flex-col gap-[24px]">
                    {/* card */}
                    <div className="flex flex-col gap-[12px]">
                        <div className="w-[584px] h-[229px] bg-neutral-200 rounded-sm"></div>
                        <div className="flex gap-[12px] items-center">
                            <div className="h-[60px] w-[60px] bg-green-100 rounded-full"></div>
                            <div className="flex flex-col">
                                <div className="text-[32px] font-bold">Хөрөнгө итгэмжлэн</div>
                                <div className="uppercase text-[20px] text-primary">удирдах</div>
                            </div>
                        </div>
                        <div className="text-[20px] text-neutral-400 text-justify">Хөрөнгө оруулалтыгн Сантай “Хөрөнгө итгэмжлэн удирдах гэрээ” байгуулсны  үндсэн дээр Сангийн нэгж эрх эзэмшигчид буюу Хөрөнгө оруулагчдын хөрөнгийг нь өсгөн нэмэгдүүлэх нэгэн зорилгоор хамтран  ажиллаж    Менежментийн  үйлчилгээ үзүүлнэ.</div>

                    </div>
                    <div className="flex flex-col gap-[12px]">
                        <div className="w-[584px] h-[229px] bg-neutral-200 rounded-sm"></div>
                        <div className="flex gap-[12px] items-center">
                            <div className="h-[60px] w-[60px] bg-green-100 rounded-full"></div>
                            <div className="flex flex-col">
                                <div className="text-[32px] font-bold">Хөрөнгө оруулалтын</div>
                                <div className="uppercase text-[20px] text-primary">удирдах</div>
                            </div>
                        </div>
                        <div className="text-[20px] text-neutral-400 text-justify">Хөрөнгө  оруулж  буй  төсөл  хөтөлбөрүүдийн гүйцэтгэл,  хэрэгжилтэд  тогтмол  хяналт  тавьж, эрсдэлээс  сэргийлнэ.</div>

                    </div>
                </div>
                <div className="flex flex-col gap-[12px]">
                    <div className="w-[584px] h-[617px] bg-neutral-200 rounded-sm"></div>
                    <div className="flex gap-[12px] items-center">
                        <div className="h-[60px] w-[60px] bg-green-100 rounded-full"></div>
                        <div className="flex flex-col">
                            <div className="text-[32px] font-bold">Судалгаа шинжилгээ</div>
                            <div className="uppercase text-[20px] text-primary">ЗӨВЛӨХ ҮЙЛЧИЛГЭЭ</div>
                        </div>
                    </div>
                    <div className="text-[20px] text-neutral-400 text-justify">МУ-ын стратеги бодлого хөгжил цэцэглэлтэд хувь нэмэр оруулах мега төслүүдэд судалгаа шинжилгээ хийж  үйлчлүүлэгчдийнхээ  зорилго,  эрэлт хэрэгцээнд нийцсэн төсөл хөтөлбөрүүдийг бэлтгэх, хөрөнгө  оруулагч  болон  төсөл  хэрэгжүүлэгч  нарт хөрөнгө оруулалтын зөвлөх үйлчилгээ үзүүлнэ.</div>

                </div>

            </div>
        </div>
    );
}

export default OurServiceView;
