import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import { AppContext } from "./AppContext";
import { createContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import Notification from "./component/Notification";

const router = createBrowserRouter(routes);


function App() {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [orderWS, setOrderWS] = useState();
  const [dialog, setDialog] = useState({ show: false, content: "" });
  const [responseError, setResponseError] = useState(null);
  const [orderSocket, setOrderSocket] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  function pushNoti(type, message) {
    setNotifications((prev) => {
      if (prev?.at(-1)?.props?.children === message)
        return [...prev]
      else
        return [
          ...prev,
          <Notification key={v4()} type={type} prev={prev}>
            {message}
          </Notification>,
        ]
    });
    setTimeout(() => {
      setNotifications((prev) => prev.slice(1));
    }, 5000);
  }

  function closeDialog() {
    setDialog({ show: false, content: "" });
  }

  return (
    <AppContext.Provider
      value={{
        setLoading,
        pushNoti,
        setDialog,
        closeDialog,
        setOrderWS,
        setResponseError,
        setOrderSocket,
        state: {
          orderWS,
          loading,
          notifications,
          dialog,
          responseError,
          orderSocket
        },
      }}
    >
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

export default App;
