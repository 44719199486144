import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useState } from "react";

function CustomCollapse(props) {
    const [value, setValue] = useState(false);

    return (
        <div
            className="flex flex-col rounded-[4px] bg-white px-[24px] py-[12px] hover:cursor-pointer"
            onClick={() => setValue(!value)}
        >
            <div className="flex justify-between items-center">
                {props.header}
                {
                    value ?
                        <ArrowDown2 />
                        :
                        <ArrowRight2 />
                }
            </div>
            {value ? props.children : <></>}
        </div>
    );
}

export default CustomCollapse;
