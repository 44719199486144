import { useState } from "react";
import GroupedBarChart from "../../../component/GroupBarChart";

function FundInformationBarChart() {
    const [range, setRange] = useState(0);

    return (
        <div className="row-span-3 flex flex-col bg-white rounded-lg border shadow-lg p-3">
            <div className="flex justify-between">
                <div className="font-semibold text-xl">Эзэмшиж буй сангийн хөрөнгийн мэдээлэл</div>
                <div className="grid grid-cols-3 gap-2 text-xs text-neutral-500">
                    <div
                        className={`border border-transparent cursor-pointer ${range === 0 ?
                            "!border-blue-500 text-blue-500"
                            : ""} bg-neutral-100 rounded p-1 px-3`}
                        onClick={() => setRange(0)}>7 хоног</div>
                    <div
                        className={`border border-transparent cursor-pointer ${range === 1 ?
                            "!border-blue-500 text-blue-500"
                            : ""} bg-neutral-100 rounded p-1 px-3`}
                        onClick={() => setRange(1)}>1 сар</div>
                    <div
                        className={`border border-transparent cursor-pointer ${range === 2 ?
                            "!border-blue-500 text-blue-500"
                            : ""} bg-neutral-100 rounded p-1 px-3`}
                        onClick={() => setRange(2)}>1 жил</div>
                </div>
            </div>
            <GroupedBarChart />
        </div>
    );
}

export default FundInformationBarChart;
