import { LoginCurve, SearchNormal1 } from "iconsax-react";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";


function Header() {
    const { setLoading, pushNoti, setDialog } = useContext(AppContext);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useMemo(() => {
        if (!user)
            setUser(JSON.parse(localStorage.getItem("user")));
    }, [localStorage.getItem("user")]);

    function showPlaceOrder(val) {
        // setDialog({
        //     show: true,
        //     showIcon: false,
        //     content: (
        //         <PlaceOrder />
        //     ),
        // });
    }
    function showDemandMoney(val) {
        // setDialog({
        //     show: true,
        //     content: (
        //         <DemandMoney />
        //     ),
        // });
    }
    function showMSCCPayment() {
        // setDialog({
        //     show: true,
        //     showIcon: false,
        //     className: "overflow-y-visible",
        //     content: (
        //         <MSCCPaymentForm />
        //     ),
        // });
    }

    return (
        <div className="grid grid-cols-5 gap-5 p-3">
            <div className="flex flex-row gap-2 items-center py-1  px-[15px] bg-white border rounded-md text-sm  text-neutral-8">
                <SearchNormal1 size={16} className="text-neutral-600" />
                <input
                    placeholder="Хайх"
                    className="bg-neutral-2 w-full outline-none"
                />
            </div>
            {/* <FormButton className="bg-[#22A06B]" text="Захиалга шивэх" onClick={showPlaceOrder} />
            <FormButton className="bg-[#dacc8d]" text="Мөнгө хүсэх" onClick={showDemandMoney} />
            <FormButton className="bg-blue-600" text="Клирингийн төлбөр" onClick={showMSCCPayment} /> */}
            <div className="flex justify-between items-center p-1 px-2 border rounded text-xs bg-white cursor-pointer">
                <div className="flex items-center gap-1" onClick={() => navigate("user-detail")}>
                    <div className="rounded-full bg-blue-500 font-bold h-[25px] w-[25px] flex items-center justify-center text-white">AD</div>
                    <div className="flex flex-col leading-tight mx-1 text-sm font-bold">
                        {user?.firstName}
                        <p className="place-items-start text-[10px] font-thin text-neutral-500">{user?.username}</p>
                    </div>
                </div>
                <LoginCurve size={20} />
            </div>
        </div>
    )
}

export default Header;