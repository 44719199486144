import { useState } from "react";
import GroupedBarChart from "../../component/GroupBarChart";
import InvestmentBarChart from "../builders/investment-bar-chart";
import FundInformationBarChart from "../builders/fund-information-bar-chart";
import DateAndWeatherCard from "../builders/date-and-weather-card";
import OurFundsCard from "../builders/our-funds-card";
import LastTransactionListCard from "../builders/last-transaction-list-card";

function DashboardView() {

    return (
        <div className="h-full w-full grid grid-cols-10 gap-5 text-neutral-500">
            <div className="grid grid-rows-7 gap-5 col-span-7">
                {
                    /**
                     * Мэндчилгээ
                     */
                }
                <div className="flex flex-col border gap-3 bg-white rounded-lg shadow-lg p-3 text-xs">
                    <div className="text-3xl text-neutral-700 font-semibold">
                        Эрхэм хэрэглэгч танд энэ өдрийн мэндийг хүргэе!
                    </div>
                    Та хувийн мэдээлэл хэсэгт орж мэдээллээ бүрэн, үнэн зөв оруулна үү? Хувийн мэдээлэл баталгаажсаны дараа та хөрөнгийн дансаа цэнэглэн хөрөнгө оруулж эхлэхэд бэлэн болно.
                </div>
                <InvestmentBarChart />
                <FundInformationBarChart />
            </div>
            <div className="grid grid-rows-8 gap-5 col-span-3">
                <DateAndWeatherCard className="row-span-2 shadow-lg" />
                <OurFundsCard className="row-span-3" />
                <LastTransactionListCard className="row-span-3" />
            </div>
        </div >
    );
}

export default DashboardView;
