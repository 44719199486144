function LoadingIcon(props) {
    return (
        <div className="animate-spin">
            <svg width={`${props.size ?? "24"}`} height={`${props.size ?? "24"}`} viewBox="0 0 12 12" color="##00904D" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2427 1.7573L9.3 2.69997C8.53661 1.9365 7.53211 1.46134 6.45766 1.35546C5.38321 1.24958 4.30529 1.51952 3.40756 2.1193C2.50983 2.71907 1.84783 3.61156 1.53436 4.64471C1.22089 5.67785 1.27535 6.78773 1.68845 7.78522C2.10156 8.78272 2.84775 9.60612 3.79989 10.1151C4.75202 10.6241 5.85119 10.7873 6.91012 10.5767C7.96904 10.3661 8.92219 9.79492 9.60718 8.96039C10.2922 8.12585 10.6666 7.07962 10.6667 5.99997H12C12 7.3881 11.5187 8.73328 10.638 9.80632C9.7574 10.8793 8.53196 11.6138 7.1705 11.8846C5.80903 12.1554 4.3958 11.9458 3.17158 11.2914C1.94736 10.6371 0.987913 9.57845 0.456707 8.29598C-0.0744978 7.01351 -0.144591 5.58652 0.258372 4.25817C0.661334 2.92981 1.51242 1.78227 2.66661 1.01108C3.82081 0.239881 5.2067 -0.107256 6.58814 0.0288133C7.96959 0.164883 9.26112 0.77574 10.2427 1.7573Z" fill="#00904D" />
            </svg>
        </div>
    )
}

export default LoadingIcon;