import { Sun, Sun1 } from "iconsax-react";

function DateAndWeatherCard(props) {

    return (
        <div className={`${props.className} grid gap-3 rounded-xl p-3 bg-gradient-to-b from-[#194E87] to-[#7F9DBD] text-base text-white`}>
            <div className="text-2xl font-bold">Улаанбаатар хот, Монгол улс</div>
            <div>11-р сарын 10, 2023 он</div>
            <div className="grid">
                <div className="flex gap-3">
                    <Sun1 />
                    Нартай
                </div>
                <div className="flex gap-3">
                    Өдөртөө: -11°
                    <span>Оройдоо: -24°</span>
                </div>
            </div>
        </div >
    );
}

export default DateAndWeatherCard;
