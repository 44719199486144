
function ExecuteBranchView() {

    return (
        <div className="flex flex-col bg-[#F3F8FD] px-[228px] my-20 gap-10">
            <div className="flex flex-col gap-3 m-auto justify-center items-center">
                <div className="text-3xl font-bold">Гүйцэтгэх удирдлага</div>
                Хөрөнгө оруулалтын сангийн гүйцэтгэх удирдлагууд
            </div>
            <div className="grid grid-cols-2 text-xs">
                <div className="flex gap-[10px]">
                    <div className="flex flex-col">

                        <div className="relative flex flex-col max-w-[230px] gap-3 items-center">
                            <img className="w-[80%] max-h-[200px] h-[200px] bg-neutral-200 rounded border-l-2 border-primary" />
                            <div className="flex flex-col text-justify gap-3 items-center justify-center h-[30%] overflow-hidden text-ellipsis rounded-md p-3">
                                <div className="w-full flex justify-between gap-1">
                                    <div className="font-bold">Ж.Эрдэнэ-учрал</div>
                                    ТУЗ-ын гишүүн
                                </div>
                                <div className="font-thin">Бизнесийн удирдлага байгууллагын менежмент төслийн удирдлага чиглэлээр мэргэшсэн туршлагатай.</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 border-l pl-[5px]">
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                    </div>
                </div>
                <div className="flex gap-[10px]">
                    <div className="flex flex-col">

                        <div className="relative flex flex-col max-w-[230px] gap-3 items-center">
                            <img className="w-[80%] max-h-[200px] h-[200px] bg-neutral-200 rounded border-l-2 border-primary" />
                            <div className="flex flex-col text-justify gap-3 items-center justify-center h-[30%] overflow-hidden text-ellipsis rounded-md p-3">
                                <div className="w-full flex justify-between gap-1">
                                    <div className="font-bold">Ж.Эрдэнэ-учрал</div>
                                    ТУЗ-ын гишүүн
                                </div>
                                <div className="font-thin">Бизнесийн удирдлага байгууллагын менежмент төслийн удирдлага чиглэлээр мэргэшсэн туршлагатай.</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 border-l pl-[5px]">
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                        <div className="relative after:z-10 pl-[10px] after:content-[''] after:absolute after:left-[-10px] after:top-0 after:w-[10px] after:h-[10px] after:rounded-full after:bg-primary">
                            Санхүүгийн удирдлагын ахлах мэргэжилтэн
                            Муис Биенесийн удирдлагын
                            доктор
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ExecuteBranchView;
