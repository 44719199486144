import { TickCircle } from "iconsax-react";
import Image1 from "../../../../../component/utils/details/img1";
import Image2 from "../../../../../component/utils/details/img2";


function UtilityView() {

    return (
        <div className="flex flex-col bg-[#F3F8FD] px-[228px] py-20 gap-10">
            <div className="flex flex-col gap-3 m-auto justify-center items-center">
                <div className="text-3xl font-bold">Бидний үнэ цэнэ</div>
                Хөрөнгө оруулалтын багцтай холбоотой стратегиудыг таньд харуулж байна
            </div>
            <div className="grid grid-cols-6 gap-10">
                <div className="col-span-2 grid grid-cols-2 gap-1 items-end">
                    <div className="relative flex w-full h-full">
                        <div className="absolute left-0 top-[10%] rounded-full w-[130%] h-[80%] bg-neutral-200 overflow-hidden">
                            <Image1 className="h-full object-center object-cover" />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex border-8 z-[1] border-white rounded-full rounded-br-none w-[80%] h-[40%] min-h-[200px] bg-neutral-200 overflow-hidden">
                            <Image2 className="object-center object-cover" /></div>
                        <div className="flex border-8 z-[1] border-white rounded-full w-full h-[60%] min-h-[300px] bg-neutral-200 overflow-hidden">
                            <Image2 className="object-center object-cover" /></div>
                    </div>
                </div>
                <div className="col-span-4 flex flex-col gap-5">
                    <div className="flex gap-3">
                        <TickCircle size={34} variant="Bold" className="min-w-[34px] text-primary" />
                        <div className="flex flex-col gap-1 text-base justify-center text-justify">
                            <div className="text-xl font-bold">Ил тод байдал</div>
                            Хөрөнгө  оруулалтын  арга  барилын  уян хатан байдал нь бидэнд хийж буй хөрөнгө оруулалтдаа  оппортунист  байхын зэрэгцээ  салбарын  дүрэм  журмын өөрчлөлтийг  ухаалгаар  шийдвэрлэж, бизнесийн  болон  зах  зээлийн  хамгийн сүүлийн үеийн хөгжил дэвшлийг дагаж мөрдөх боломжийг олгоно
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <TickCircle size={34} variant="Bold" className="min-w-[34px] text-primary" />
                        <div className="flex flex-col gap-1 text-base justify-center text-justify">
                            <div className="text-xl font-bold">Бие даасан хараат бус бүтэц</div>
                            Бие  даасан  бүтэц  нь  үйлчлүүлэгчдэд хамгийн ашигтай шийдвэр гаргах, тэдний хөрөнгө оруулалтын зорилго хэрэгцээнд нийцүүлэх боломжийг олгодог.
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <TickCircle size={34} variant="Bold" className="min-w-[34px] text-primary" />
                        <div className="flex flex-col gap-1 text-sm justify-center text-justify">
                            <div className="text-lg font-bold">Тогтвортой хөгжлийн бодлого</div>
                            Бидний  хийж  буй  зүйл, үйлчлүүлэгчийн  нууцлал,  гуйвшгүй ёс зүй, цаг тухайд нь хариу үйлдэл үзүүлэх  нь  бидний  үндсэн  бизнес, үйлчлүүлэгчийн үнэт зүйл болдог
                        </div>
                    </div>
                    <div className="flex gap-3">
                        <TickCircle size={34} variant="Bold" className="min-w-[34px] text-primary" />
                        <div className="flex flex-col gap-1 text-sm justify-center text-justify">
                            <div className="text-lg font-bold">Уян хатан байдал</div>
                            Бид  дэлхийн  болон  нийгэмд эерэг  нөлөө  үзүүлэхийн  зэрэгцээ үйлчлүүлэгчдийн  баялгийн тогтвортой өсөлтийг эрэлхийлдэг
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default UtilityView;
