function WebIconWithTitle(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="36" viewBox="0 0 150 36" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21164 27.6485V20.5713L7.06602 16.7141V32.1428L3.21164 36V28.9332H0V9.66118L1.28475 8.37629V27.6485H3.21164ZM12.8735 28.9332V32.1428L9.01901 36V8.37629H1.28475L2.56949 7.09154H9.01901V3.85705L12.8735 -0.0001297V27.6469H20.582L19.2973 28.9332H12.8735ZM20.582 27.6469V8.35211H18.6551V15.4285L14.8007 19.2856V3.85705L18.6551 -0.0001297V7.06575H21.8668V26.3607L20.582 27.6469Z" fill="#1CB78D" />
            <path d="M149.157 21.9328C148.349 21.9328 147.542 21.9238 146.735 21.9359C146.547 21.9359 146.466 21.8603 146.39 21.7091C146.03 21.0016 145.664 20.3001 145.292 19.5986C145.256 19.5321 145.165 19.4686 145.09 19.4596C144.938 19.4384 144.781 19.4535 144.612 19.4535V21.9117H142.25V15.027H144.597V17.5789C144.808 17.5789 145.002 17.585 145.196 17.5729C145.241 17.5729 145.292 17.5063 145.319 17.461C145.767 16.696 146.215 15.9311 146.644 15.154C146.713 15.027 146.783 15.0059 146.904 15.0059C147.654 15.0059 148.404 15.0059 149.154 15.0059V15.2024C149.121 15.2205 149.069 15.2326 149.051 15.2629C148.455 16.2364 147.86 17.2131 147.267 18.1897C147.206 18.2894 147.146 18.356 147.231 18.5041C147.793 19.4686 148.331 20.4513 148.882 21.4219C148.948 21.5398 149.06 21.6305 149.151 21.7333V21.9298L149.157 21.9328Z" fill="white" />
            <path d="M136.275 19.8748H138.371V15.0293H140.735V19.8864C140.98 19.8864 141.204 19.8719 141.419 19.8952C141.494 19.9039 141.618 20.0031 141.618 20.0614C141.627 21.0235 141.621 21.9856 141.618 22.9448C141.618 22.9593 141.606 22.971 141.591 23.0001H139.471V21.6853H133.947V15.0264H136.272V19.8748H136.275Z" fill="white" />
            <path d="M125.867 15.0091C126.698 15.0091 127.472 15.0061 128.244 15.0152C128.316 15.0152 128.416 15.0759 128.455 15.1365C128.876 15.8526 129.29 16.5717 129.737 17.3484C129.858 17.1573 129.964 16.9995 130.058 16.8357C130.39 16.2774 130.717 15.713 131.053 15.1547C131.092 15.088 131.183 15.0121 131.252 15.0121C131.966 15.003 132.683 15.0061 133.43 15.0061C132.94 15.8496 132.468 16.6597 131.996 17.4698C131.639 18.0857 131.276 18.6986 130.926 19.3176C130.877 19.4026 130.85 19.5118 130.85 19.6119C130.844 20.3795 130.847 21.1472 130.847 21.93H128.479V21.7055C128.479 21.1017 128.47 20.4979 128.482 19.8941C128.488 19.5967 128.443 19.3328 128.283 19.0688C127.799 18.2799 127.336 17.4789 126.868 16.6809C126.547 16.1378 126.224 15.5947 125.87 15L125.867 15.0091Z" fill="white" />
            <path d="M98.7296 22.2568V19.3171L98.681 19.3019C98.3981 19.7822 98.1183 20.2655 97.8355 20.7459C97.6774 21.0104 97.5223 21.2779 97.3459 21.5302C97.2972 21.6001 97.1908 21.6609 97.1026 21.67C96.9049 21.6913 96.7012 21.6548 96.5065 21.6822C96.2693 21.7126 96.1598 21.588 96.0504 21.4086C95.655 20.7489 95.2505 20.0953 94.8491 19.4387C94.84 19.4204 94.8187 19.4113 94.77 19.3627V22.2568H92.5986V15.3346C92.6959 15.3286 92.7933 15.3194 92.8906 15.3194C93.362 15.3194 93.8364 15.3286 94.3078 15.3134C94.4963 15.3073 94.6028 15.3772 94.697 15.5322C95.3114 16.5628 95.9317 17.5873 96.5552 18.6148C96.6191 18.7212 96.7012 18.8155 96.7985 18.9492C97.0935 18.4537 97.3672 17.9916 97.6409 17.5265C98.0484 16.8334 98.4498 16.1372 98.8634 15.4471C98.8969 15.3894 98.9881 15.3286 99.052 15.3286C99.6633 15.3194 100.278 15.3225 100.907 15.3225V22.2568H98.7296Z" fill="white" />
            <path d="M48.8698 22.2873V21.9741C48.8698 19.837 48.8698 17.6999 48.8668 15.5627C48.8668 15.3651 48.9185 15.2283 49.0705 15.0824C49.7304 14.4471 50.3691 13.7904 51.0199 13.1429C51.0716 13.0912 51.1507 13.0669 51.2723 13V18.6848L51.321 18.697C51.5339 18.3869 51.7498 18.0768 51.9566 17.7637C52.4036 17.0827 52.8476 16.3957 53.2917 15.7117C53.3129 15.6783 53.3433 15.6509 53.3586 15.6144C53.4589 15.3895 53.611 15.3013 53.8725 15.3104C54.4929 15.3317 55.1133 15.3165 55.7459 15.3165V22.2569H53.4589V18.8399L53.3981 18.8277C52.8172 19.7215 52.2364 20.6153 51.6555 21.509C51.5126 21.7279 51.3727 21.9468 51.2206 22.1596C51.178 22.2173 51.099 22.2812 51.0351 22.2812C50.3235 22.2903 49.6149 22.2873 48.8698 22.2873Z" fill="white" />
            <path d="M70.3617 18.6514C70.8969 18.8824 71.2436 19.232 71.3835 19.7914C71.5781 20.5605 71.3713 21.1837 70.7783 21.6884C70.4742 21.9468 70.1214 22.1322 69.72 22.1717C69.1726 22.2234 68.6221 22.2629 68.0717 22.2721C67.0286 22.2873 65.9854 22.2751 64.9241 22.2751V15.3347C64.9636 15.3286 65.0092 15.3165 65.0579 15.3165C66.3838 15.3165 67.7098 15.2922 69.0327 15.3317C69.6561 15.3499 70.2644 15.5354 70.7084 16.0218C71.0855 16.4352 71.2406 16.9125 71.1037 17.4901C70.9973 17.9339 70.8057 18.2896 70.4134 18.5328C70.386 18.5511 70.383 18.6058 70.3647 18.6514H70.3617ZM68.0352 19.5877C68.02 19.5877 68.0017 19.5877 67.9865 19.5877C67.7402 19.5968 67.3874 19.5148 67.2718 19.6424C67.1411 19.7884 67.2262 20.1228 67.2049 20.3751C67.1897 20.5544 67.2688 20.6061 67.4361 20.6031C67.8253 20.594 68.2146 20.6 68.6039 20.5909C68.905 20.5848 69.1513 20.2626 69.0601 19.9768C68.9779 19.7184 68.8867 19.5725 68.5583 19.5877C68.3849 19.5938 68.2116 19.5877 68.0352 19.5877ZM67.8679 16.9885C67.4219 16.9966 67.2009 17.2266 67.2049 17.6786C67.2049 17.7211 67.1897 17.7728 67.208 17.8002C67.2627 17.8701 67.3296 17.9735 67.3965 17.9795C67.6915 17.9978 67.9926 18.0039 68.2876 17.9795C68.5431 17.9613 68.7681 17.7485 68.759 17.5235C68.7499 17.329 68.7012 17.0979 68.4762 17.0371C68.2815 16.9855 68.0686 17.0037 67.8649 16.9885H67.8679Z" fill="white" />
            <path d="M56.9502 15.3286H59.3162C59.3162 15.4289 59.3162 15.5141 59.3162 15.6022C59.3162 16.2406 59.3253 16.879 59.3132 17.5174C59.3101 17.7363 59.3801 17.8032 59.599 17.7941C60.0948 17.7789 60.5935 17.7819 61.0923 17.7941C61.3234 17.8002 61.4177 17.7211 61.4146 17.4809C61.4025 16.8608 61.4116 16.2376 61.4116 15.6174V15.3347H63.7806V22.2538H61.4237C61.4207 22.1747 61.4116 22.0896 61.4116 22.0015C61.4116 21.3387 61.4025 20.676 61.4146 20.0133C61.4177 19.7731 61.3264 19.6941 61.0923 19.7002C60.5935 19.7123 60.0978 19.7154 59.599 19.7002C59.3801 19.6941 59.3101 19.7579 59.3132 19.9768C59.3253 20.6395 59.3162 21.3023 59.3162 21.965C59.3162 22.0531 59.3162 22.1443 59.3162 22.2538H56.9502V15.3286Z" fill="white" />
            <path d="M108.708 15.3228H111.071V15.5872C111.071 16.2408 111.077 16.8975 111.068 17.5511C111.068 17.7335 111.122 17.7943 111.308 17.7913C111.846 17.7791 112.388 17.7761 112.926 17.7913C113.136 17.7973 113.175 17.7153 113.172 17.5328C113.166 16.8063 113.172 16.0767 113.172 15.3319H115.532V22.254H113.172V20.2415C113.172 19.8828 112.995 19.7034 112.64 19.7034C112.193 19.7034 111.743 19.7125 111.296 19.7004C111.116 19.6943 111.068 19.7612 111.071 19.9284C111.077 20.6094 111.071 21.2873 111.071 21.9682V22.257H108.708V15.3228Z" fill="white" />
            <path d="M74.5463 17.1039V17.8912H77.4172V19.5906C77.3199 19.5967 77.2256 19.6058 77.1283 19.6058C76.3497 19.6058 75.5712 19.6149 74.7896 19.6028C74.5859 19.5997 74.5281 19.6727 74.5372 19.8612C74.5463 20.0588 74.5372 20.2564 74.5372 20.4844H77.9311V22.2598H72.1833V15.3315H77.8156V17.1039H74.5402H74.5463Z" fill="white" />
            <path d="M102.045 22.26V15.3379H107.674V17.0981H104.423V17.8945H107.278V19.603H106.807C106.077 19.603 105.347 19.6061 104.617 19.6C104.456 19.6 104.392 19.6456 104.398 19.8098C104.408 20.0226 104.398 20.2384 104.398 20.4755H107.78V22.26H102.042H102.045Z" fill="white" />
            <path d="M83.9496 17.9033C83.4752 17.3136 82.8822 16.9974 82.1158 17.1038C81.7965 17.1494 81.5197 17.2984 81.2977 17.5355C80.6591 18.2104 80.6743 19.4355 81.3129 20.0739C81.8299 20.5907 82.9521 20.8522 83.9648 19.703C84.4028 20.0952 84.8346 20.4904 85.2756 20.8795C85.4215 21.0072 85.4307 21.1106 85.2908 21.2534C84.8072 21.7459 84.2568 22.1107 83.5725 22.287C83.0099 22.433 82.4473 22.5211 81.8755 22.4512C80.8476 22.3235 79.9231 21.9496 79.248 21.1258C78.8709 20.6637 78.585 20.1438 78.5242 19.5328C78.4938 19.2075 78.4269 18.8792 78.4451 18.5569C78.5181 17.3105 79.1872 16.0884 80.5283 15.5048C81.2217 15.2008 81.9485 15.0579 82.6967 15.1339C83.7002 15.2342 84.6004 15.5747 85.2969 16.3499C85.4246 16.4928 85.4094 16.587 85.2756 16.7056C84.8346 17.0947 84.3997 17.4899 83.9466 17.9003L83.9496 17.9033Z" fill="white" />
            <path d="M89.886 17.1922V22.2569H87.5777V21.9589C87.5777 20.4845 87.5777 19.0101 87.5777 17.5357C87.5777 17.3067 87.4581 17.1922 87.2189 17.1922C86.7384 17.1922 86.2579 17.1922 85.7773 17.1922C85.6253 17.1922 85.5158 17.1436 85.5158 16.9733C85.5158 16.4352 85.5158 15.9002 85.5158 15.3408H91.9539C91.9539 15.8971 91.957 16.4413 91.9539 16.9855C91.9539 17.1557 91.8323 17.1922 91.6833 17.1922C91.0963 17.1892 90.5064 17.1922 89.889 17.1922H89.886Z" fill="white" />
            <path d="M116.238 15.3286H122.615C122.615 15.9032 122.615 16.4625 122.615 17.0249C122.615 17.1921 122.487 17.1921 122.366 17.1921C121.861 17.1921 121.353 17.2013 120.848 17.1861C120.614 17.18 120.535 17.2438 120.538 17.4901C120.55 18.9736 120.544 20.4571 120.544 21.9407V22.2568H118.251V17.1921C117.658 17.1921 117.095 17.18 116.533 17.1982C116.305 17.2043 116.229 17.1374 116.235 16.9094C116.25 16.3896 116.238 15.8697 116.238 15.3317V15.3286Z" fill="white" />
            <path d="M40.8999 15.0029C40.8999 15.7113 40.906 16.4044 40.8968 17.1005C40.8938 17.4045 40.9456 17.4714 41.2654 17.4623C41.762 17.4501 42.2616 17.4532 42.7612 17.4623C42.9836 17.4653 43.0719 17.3893 43.0689 17.1613C43.0598 16.45 43.0659 15.7356 43.0659 15.006H45.3689V21.9281H43.0659C43.0659 21.6819 43.0659 21.4356 43.0659 21.1894C43.0659 20.6847 43.0598 20.177 43.0689 19.6724C43.075 19.4444 42.9836 19.3684 42.7612 19.3714C42.2464 19.3806 41.7315 19.3836 41.2167 19.3714C40.9821 19.3654 40.8907 19.4474 40.8968 19.6846C40.909 20.3382 40.8999 20.9948 40.8999 21.6484C40.8999 21.7366 40.8999 21.8278 40.8999 21.9311H38.5999V15.0029H40.8999Z" fill="white" />
            <path d="M37.5672 16.7753H34.2375V17.5596H37.1833C37.1833 18.119 37.1894 18.6388 37.1711 19.1556C37.1711 19.1982 37.0097 19.2681 36.9244 19.2681C36.1354 19.2772 35.3463 19.2742 34.5573 19.2742C34.2375 19.2742 34.2222 19.2894 34.2222 19.6177C34.2222 19.7666 34.2222 19.9156 34.2253 20.0646C34.2253 20.0798 34.2344 20.095 34.2496 20.1406H37.6799V21.9311H31.9344V15.0029H37.5672V16.7753Z" fill="white" />
            <path d="M29.22 21.9312H26.85V16.8757H24.8668V15H31.2002V16.8544H29.2231V21.9312H29.22Z" fill="white" />
        </svg>
    )
}

export default WebIconWithTitle;