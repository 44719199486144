
function Lamp(props) {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="20" height="27" viewBox="0 0 20 27" fill="none">
            <g id="Vector">
                <path d="M9.625 0C4.30926 0 0 4.30926 0 9.625C0 13.1842 1.93237 16.2911 4.80102 17.9555C5.60072 18.4195 6.1015 19.1315 6.12416 19.848C6.13666 20.2432 6.41266 20.5809 6.79747 20.6719C7.20741 20.7687 7.62516 20.845 8.04952 20.8995C8.42706 20.948 8.75021 20.6456 8.75021 20.265V14.8271C8.37772 14.7859 8.0127 14.7187 7.65711 14.6271C7.18911 14.5067 6.90738 14.0296 7.02783 13.5617C7.14828 13.0937 7.62532 12.8119 8.09331 12.9324C8.58193 13.0581 9.09505 13.1252 9.62521 13.1252C10.1554 13.1252 10.6685 13.0581 11.1571 12.9324C11.6251 12.8119 12.1021 13.0937 12.2226 13.5617C12.343 14.0296 12.0613 14.5067 11.5933 14.6271C11.2377 14.7187 10.8727 14.7859 10.5002 14.8271V20.2649C10.5002 20.6456 10.8234 20.948 11.2009 20.8995C11.6251 20.845 12.0427 20.7687 12.4525 20.6719C12.8373 20.5809 13.1133 20.2432 13.1258 19.848C13.1485 19.1315 13.6493 18.4195 14.449 17.9555C17.3176 16.2911 19.25 13.1842 19.25 9.625C19.25 4.30926 14.9407 0 9.625 0Z" fill="#1CB78D" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.14033 22.3413C6.23039 21.8665 6.68829 21.5546 7.16307 21.6447C7.95987 21.7958 8.78278 21.875 9.625 21.875C10.4672 21.875 11.2901 21.7958 12.0869 21.6447C12.5617 21.5546 13.0196 21.8665 13.1097 22.3413C13.1997 22.816 12.8879 23.2739 12.4131 23.364C11.5093 23.5354 10.5772 23.625 9.625 23.625C8.6728 23.625 7.7407 23.5354 6.83693 23.364C6.36214 23.2739 6.05027 22.816 6.14033 22.3413Z" fill="#1CB78D" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.00475 25.193C7.05503 24.7123 7.48541 24.3635 7.96604 24.4137C8.51096 24.4708 9.06442 24.5 9.625 24.5C10.1856 24.5 10.739 24.4708 11.284 24.4137C11.7646 24.3635 12.195 24.7123 12.2453 25.193C12.2955 25.6736 11.9467 26.104 11.466 26.1542C10.8607 26.2176 10.2465 26.25 9.625 26.25C9.00349 26.25 8.38927 26.2176 7.78396 26.1542C7.30334 26.104 6.95447 25.6736 7.00475 25.193Z" fill="#1CB78D" />
            </g>
        </svg>
    )
}

export default Lamp;