
function ContactView(props) {

    return (
        <div className="flex flex-col px-[228px] py-[100px] gap-[48px] bg-[#202D3B] text-white">
            hi
        </div>
    );
}

export default ContactView;
