
function BoardView() {

    return (
        <div className="flex flex-col bg-[#F3F8FD] px-[228px] my-20 gap-10">
            <div className="flex flex-col gap-3 m-auto justify-center items-center">
                <div className="text-3xl font-bold">Төлөөлөн удирдах зөвлөл</div>
                Хөрөнгө оруулалтын сангийн төлөөлөн удирдах зөвлөлийн ажлын хэсэг
            </div>
            <div className="grid grid-cols-3 w-[calc(100%-100px)] m-auto gap-[200px] items-center justify-center text-xs">
                <div className="relative bg-neutral-200 rounded border-l-2 border-primary">
                    <div className="h-[350px]">
                        <img />
                    </div>
                    <div className="flex flex-col text-justify gap-1 items-center justify-center absolute bottom-0 left-[-50px] w-[calc(100%+100px)] bg-white border h-[30%] rounded-md p-3">
                        <div className="w-full flex justify-between gap-1 text-base">
                            <div className="font-bold">Ж.Эрдэнэ-учрал</div>
                            ТУЗ-ын гишүүн
                        </div>
                        <div className="font-thin">Бизнесийн удирдлага байгууллагын менежмент төслийн удирдлага чиглэлээр мэргэшсэн туршлагатай.</div>
                    </div>
                </div>
                <div className="relative bg-neutral-200 rounded border-l-2 border-primary">
                    <div className="h-[350px]">
                        <img />
                    </div>
                    <div className="flex flex-col text-justify gap-1 items-center justify-center absolute bottom-0 left-[-50px] w-[calc(100%+100px)] bg-white border h-[30%] rounded-md p-3">
                        <div className="w-full flex justify-between gap-1 text-base">
                            <div className="font-bold">Ж.Эрдэнэ-учрал</div>
                            ТУЗ-ын гишүүн
                        </div>
                        <div className="font-thin">Бизнесийн удирдлага байгууллагын менежмент төслийн удирдлага чиглэлээр мэргэшсэн туршлагатай.</div>
                    </div>
                </div>
                <div className="relative bg-neutral-200 rounded border-l-2 border-primary">
                    <div className="h-[350px]">
                        <img />
                    </div>
                    <div className="flex flex-col text-justify gap-1 items-center justify-center absolute bottom-0 left-[-50px] w-[calc(100%+100px)] bg-white border h-[30%] rounded-md p-3">
                        <div className="w-full flex justify-between gap-1 text-base">
                            <div className="font-bold">Ж.Эрдэнэ-учрал</div>
                            ТУЗ-ын гишүүн
                        </div>
                        <div className="font-thin">Бизнесийн удирдлага байгууллагын менежмент төслийн удирдлага чиглэлээр мэргэшсэн туршлагатай.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BoardView;
