import { Outlet } from "react-router-dom";
import IntroHeader from "./utils/intro-header";

function IntroductionView() {

    return (
        <div className="flex flex-col">
            <Outlet />
        </div>
    );
}

export default IntroductionView;
