
function ChartBoard(props) {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <g id="Icon">
                <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M2.625 2.625C2.14175 2.625 1.75 3.01675 1.75 3.5C1.75 3.98325 2.14175 4.375 2.625 4.375H3.5V16.625C3.5 18.558 5.067 20.125 7 20.125H8.411L7.0449 24.2233C6.89209 24.6818 7.13985 25.1773 7.5983 25.3301C8.05675 25.4829 8.55228 25.2351 8.7051 24.7767L9.089 23.625H18.911L19.2949 24.7767C19.4477 25.2351 19.9432 25.4829 20.4017 25.3301C20.8601 25.1773 21.1079 24.6818 20.9551 24.2233L19.589 20.125H21C22.933 20.125 24.5 18.558 24.5 16.625V4.375H25.375C25.8582 4.375 26.25 3.98325 26.25 3.5C26.25 3.01675 25.8582 2.625 25.375 2.625H2.625ZM9.67233 21.875L10.2557 20.125H17.7443L18.3277 21.875H9.67233ZM18.375 7.875C18.375 7.39175 17.9832 7 17.5 7C17.0168 7 16.625 7.39175 16.625 7.875V14.875C16.625 15.3583 17.0168 15.75 17.5 15.75C17.9832 15.75 18.375 15.3583 18.375 14.875V7.875ZM14.875 10.5C14.875 10.0168 14.4832 9.625 14 9.625C13.5168 9.625 13.125 10.0168 13.125 10.5V14.875C13.125 15.3583 13.5168 15.75 14 15.75C14.4832 15.75 14.875 15.3583 14.875 14.875V10.5ZM11.375 13.125C11.375 12.6418 10.9832 12.25 10.5 12.25C10.0168 12.25 9.625 12.6418 9.625 13.125V14.875C9.625 15.3583 10.0168 15.75 10.5 15.75C10.9832 15.75 11.375 15.3583 11.375 14.875V13.125Z" fill="#1CB78D" />
            </g>
        </svg>
    )
}

export default ChartBoard;