
function OrganisationView() {

    return (
        <div className="flex flex-col bg-white px-[228px] py-20 gap-10">
            <div className="flex flex-col gap-3 m-auto justify-center items-center">
                <div className="text-3xl font-bold">Бүтэц зохион байгуулалт</div>
                Хөрөнгө оруулалтын сангийн байгууллагын бүтэц зохион байгуулалт
            </div>

            <div className="tree flex flex-col items-center justify-center text-sm text-black">
                <div className={`relative py-5 px-14 rounded border text-center border-primary mb-[25px] bg-primary text-white
        after:content-[''] after:absolute after:border-r after:border-primary after:h-[50px] after:left-[50%] after:top-[100%]`}>
                    Хувьцаа эзэмшигч
                </div>
                <div className={`relative bg-green-50 py-5 px-14 rounded border text-center border-primary mb-[25px]
        after:content-[''] after:absolute after:border-r after:border-primary after:h-[26px] after:left-[50%] after:top-[100%]`}>
                    Төлөөлөн удирдах зөвлөл
                </div>
                <div className="w-full grid grid-cols-5 gap-[50px] border-t border-primary py-[25px]">
                    <div className={`relative flex items-center justify-center bg-green-50 p-3 rounded border text-center border-primary z-[11]
        after:content-[''] after:absolute after:border-r after:border-primary after:h-[25px] after:left-[50%] after:top-[-26px]
        before:content-[''] before:absolute before:border-orange-200 before:h-[51px] before:w-[50%] before:left-0 before:bottom-[calc(100%+2px)] before:bg-white before:z-10`}>
                        Нэр дэвшүүлэх хороо
                    </div>
                    <div className={`relative flex items-center justify-center bg-green-50 p-3 rounded border text-center border-primary z-10
        after:content-[''] after:absolute after:border-b after:border-primary after:w-[51px] after:left-[-51px] after:top-[50%]`}>
                        Аудитын хороо
                    </div>
                    <div className={`relative flex items-center justify-center bg-green-50 p-3 rounded border text-center border-primary
        after:content-[''] after:absolute after:border-b after:border-primary after:w-[51px] after:left-[-51px] after:top-[50%]
        before:content-[''] before:absolute before:border-b before:border-primary before:w-[51px] before:right-[-51px] before:top-[50%]`}>
                        Цалин, урамшууллын хороо
                    </div>
                    <div className={`relative flex items-center justify-center bg-green-50 p-3 rounded border text-center border-primary
        after:content-[''] after:absolute after:border-r after:border-orange-200 after:h-[25px] after:left-[50%] after:top-[calc(100%+1px)]
        before:content-[''] before:absolute before:border-b before:border-primary before:w-[51px] before:right-[-51px] before:top-[50%]`}>
                        Гүйцэтгэх захирал
                    </div>
                    <div className={`relative flex items-center justify-center bg-green-50 p-3 rounded border text-center border-primary
        after:content-[''] after:absolute after:border-r after:border-primary after:h-[25px] after:left-[50%] after:top-[-26px]
        before:content-[''] before:absolute before:border-orange-200 before:h-[51px] before:w-[calc(50%-1px)] before:right-0 before:bottom-[calc(100%+2px)] before:bg-white before:z-10`}>
                        Дотоод хяналтын алба
                    </div>
                </div>
                <div className="w-full grid grid-cols-7">
                    <div className="col-span-2"></div>
                    <div className="col-span-5 grid grid-cols-5 gap-[15px] pt-[25px] border-t border-orange-200">
                        <div className={`relative flex items-center justify-center bg-orange-50 p-2 px-3 rounded border text-center border-orange-200 z-10
        after:content-[''] after:absolute after:border-r after:border-orange-200 after:h-[25px] after:left-[50%] after:bottom-[calc(100%+1px)]
        before:content-[''] before:absolute before:border-orange-200 before:h-[25px] before:w-[50%] before:left-0 before:bottom-[calc(100%+2px)] before:bg-white before:z-10`}>
                            Санхүүгийн алба
                        </div>
                        <div className={`relative flex items-center justify-center bg-orange-50 p-2 px-3 rounded border text-center border-orange-200
        after:content-[''] after:absolute after:border-b after:border-orange-200 after:w-[25px] after:left-[-25px] after:top-[50%]
        before:content-[''] before:absolute before:border-b before:border-orange-200 before:w-[25px] before:right-[-25px] before:top-[50%]`}>
                            Хамтын сангийн удирдлагын алба
                        </div>
                        <div className={`relative flex items-center justify-center bg-orange-50 p-2 px-3 rounded border text-center border-orange-200 z-10`}>
                            Судалгааны алба
                        </div>
                        <div className={`relative flex items-center justify-center bg-orange-50 p-2 px-3 rounded border text-center border-orange-200
        after:content-[''] after:absolute after:border-b after:border-orange-200 after:w-[25px] after:left-[-25px] after:top-[50%]
        before:content-[''] before:absolute before:border-b before:border-orange-200 before:w-[25px] before:right-[-25px] before:top-[50%]`}>
                            Хувийн сангийн удирдлагын алба
                        </div>
                        <div className={`relative flex items-center justify-center bg-orange-50 p-2 px-3 rounded border text-center border-orange-200
        after:content-[''] after:absolute after:border-r after:border-orange-200 after:h-[25px] after:left-[50%] after:bottom-[calc(100%+1px)]
        before:content-[''] before:absolute before:border-orange-200 before:h-[25px] before:w-[calc(50%-1px)] before:right-0 before:bottom-[calc(100%+2px)] before:bg-white before:z-10`}>
                            Эрсдлийн удирдлагын алба
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrganisationView;
