
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function GroupedBarChart(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const state = {

        series: [
            {
                name: 'Call pro',
                group: 'actual',
                data: [50, 80, 45, 55, 50, 80, 45, 55, 50, 80, 45, 55]
            },
            {
                name: 'Утсаар нэвтэрсэн',
                group: 'actual',
                data: [25, 15, 11, 23, 25, 15, 11, 75, 25, 15, 11, 75]
            },
            {
                name: 'Оператор',
                group: 'actual',
                data: [34, 21, 21, 65, 34, 21, 21, 65, 34, 21, 21, 65]
            }
        ],
        options: {
            grid: {
                strokeDashArray: 5,
            },
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
                // formatter: (val) => {
                //     return val / 1000 + 'K'
                // }
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    borderRadiusApplication: 'end',
                    borderRadiusWhenStacked: 'last',
                    horizontal: false
                }
            },
            xaxis: {
                categories: [
                    '1-р сар',
                    '2-р сар',
                    '3-р сар',
                    '4-р сар',
                    '5-р сар',
                    '6-р сар',
                    '7-р сар',
                    '8-р сар',
                    '9-р сар',
                    '10-р сар',
                    '11-р сар',
                    '12-р сар',
                ]
            },
            colors: ['#3b71f1', '#6791f4', '#92b0f7'],
            legend: {
                show: false,
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="bg-neutral-500 text-white rounded !border-0 !border-none p-1 px-2 text-xs">' +
                        w.globals.seriesNames[seriesIndex] +
                        "</div>"
                    );
                }
            }
        },


    };


    return (
        <ReactApexChart options={state.options} series={state.series} type="bar" height={props.height ?? 250} className={props.className} />
    )
}

export default GroupedBarChart;  