import { InfoCircle, TickCircle, Warning2 } from "iconsax-react";
import { useEffect, useState } from "react";

function Notification(props) {
    const [type, setType] = useState('primary')

    useEffect(() => {
        if (props.type === 'alert') {
            setType('alert')
        } else if (props.type === 'warning') {
            setType('warning')
        }
    }, [props])

    return (
        <div className={"flex gap-3 items-center opacity-0 m-4 animate-noti w-[500px] p-3 border-l-[3px] rounded text-sm text-neutral-9 "
            + (type === 'primary' ? 'bg-green-300 border-green-600'
                : (type === 'alert' ? 'bg-red-300 border-red-600'
                    : 'bg-amber-100 border-amber-700'))}>
            {
                type === 'primary' ?
                    (
                        <TickCircle size={'20px'} color="#00904D" />
                    ) : (
                        type === 'alert' ?
                            (
                                <InfoCircle size={'20px'} color="#E34935" />
                            ) :
                            (
                                <Warning2 size={'20px'} color="#B8AE85" />
                            )
                    )
            }
            <div>{props.children}</div>
        </div>
    )
}

export default Notification;