import { Outlet } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../AppContext";
import Navigation from "../../component/Navigation";
import Header from "../../component/Header";

function MainView() {
    const { setLoading } = useContext(AppContext);
    const [router, setRouter] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <div className="grid grid-cols-[min-content_auto] h-screen ">
            <Navigation />
            <div className="grid grid-rows-[64px_auto] h-screen">
                <Header />
                <div className="grid grid-cols-[auto_max-content] h-[calc(100vh-64px)]">
                    <div className="p-5 px-auto h-full w-full overflow-y-auto noscroll rounded-tl-2xl  bg-[#E3F7E9]">
                        <div className="h-full">
                            {" "}
                            <Outlet />{" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainView;
