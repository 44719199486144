import CalculationView from "../views/calculation-view";
import DashboardView from "../views/dashboard-view";
import FundsView from "../views/funds-view";
import IntroductionView from "../views/introduction-web";
import ContactView from "../views/introduction-web/views/main-view/contact-view";
import DetailedIntroView from "../views/introduction-web/views/detailed-intro-view";
import IntroductionMainView from "../views/introduction-web/views/main-view";
import InvestmentView from "../views/investment-view";
import Layout from "../views/layout";
import MainView from "../views/main";
import NewsfeedView from "../views/newsfeed-view";
import PersonalsView from "../views/personals-view";


const routes = [
    {
        path: "",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <IntroductionView />,
                children: [
                    {
                        path: "",
                        element: <IntroductionMainView />
                    },
                    {
                        path: "aboutus",
                        element: <DetailedIntroView />
                    },
                    {
                        path: "contact",
                        element: <ContactView />
                    }
                ]
            },
            {
                path: "dashboard",
                element: <MainView />,
                children: [
                    {
                        path: "",
                        element: <DashboardView />
                    },
                    {
                        path: "investment",
                        element: <InvestmentView />
                    },
                    {
                        path: "personals",
                        element: <PersonalsView />
                    },
                    {
                        path: "funds",
                        element: <FundsView />
                    },
                    {
                        path: "calculation",
                        element: <CalculationView />
                    },
                    {
                        path: "newsfeed",
                        element: <NewsfeedView />
                    }
                ]
            }
        ]
    },
];

export default routes;
