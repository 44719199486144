import { ArrowRight2 } from "iconsax-react";
import CustomCollapse from "../../../../../component/Collapse";

function InformationView() {

    return (
        <div className="flex flex-col px-[352px] py-[100px] gap-[72px]">
            <div className="flex flex-col text-[24px] text-center">
                <div className="font-bold text-[32px]">Та үүнийг мэдэх үү ?</div>
                ХОС  дэлхийд үүсч хөгжөөд 250 жил болж байгаа ба Монгол Улсад дөнгөж үүсч байгаа шинэ салбар юм.
            </div>
            <div className="grid grid-cols-5 gap-[24px] font-thin">
                <div className="col-span-2 flex flex-col px-[36px] py-[24px] gap-[12px] rounded-[4px] bg-white">
                    <div className="font-bold text-[24px] border-b pb-2">Хөрөнгө оруулалт</div>
                    <div className="text-[16px] font-medium text-justify">       Анх 2013 онд Хөрөнгө оруулалтын сангийн тухай хууль батлагдсан нь энэ салбарын хөгжилд томоохон түлхэц болж энэхүү зах зээлд оролцох итгэлийг хөрөнгө оруулагчдад бүрдүүлж өгсөн. Хөрөнгө оруулалтын сангийн хууль эрх зүйн зохицуулалт, татварын хөнгөлөлт, улс эх орны асар их нөөц баялаг болон хөрөнгийн зах зээлд бий болж буй томоохон боломжууд нь хөрөнгө оруулалтын санг ирээдүйд асар хурдацтай өсч хөгжих салбар гэдгийг харуулж байна.</div>
                    <div className="w-full h-[172px] rounded-[4px] bg-neutral-200">     </div>
                    <div className="py-[12px] px-[24px] bg-[#202D3B] rounded-[4px] text-[16px] font-bold text-white text-center">Асуулт асуух</div>
                </div>
                <div className="col-span-3 flex flex-col gap-[12px]">
                    <CustomCollapse
                        header="Монгол улсад үйл ажиллагаа явуулах эрхтэй хэдэн сан байдаг вэ ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                    <CustomCollapse
                        header="Монгол улсын хамгийн том хөрөнгө оруулалтын сан юу вэ ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                    <CustomCollapse
                        header="Дэлхийн жишигт хөл зэрэгцэн алхаж буй сангийн тухай мэдэх үү ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                    <CustomCollapse
                        header="Хөрөнгө оруулалтын сан Монгол улсад хэд байдаг вэ ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                    <CustomCollapse
                        header="Хөрөнгө оруулалтын сангийн үйл ажиллагааны чиглэл юу вэ ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                    <CustomCollapse
                        header="Шилдэг хөрөнгө оруулагчдыг та мэдэх үү ?"
                    >
                        <ul className="list-disc text-[16px] font-thin list-inside">
                            <li>ХОМК 38.</li>
                            <li>Хувийн ХОС 29.</li>
                            <li>Хамтын хаалттай 2 ХОС.</li>
                            <li>Хамтын нээлттэй 3 ХОС байна.</li>
                        </ul>
                    </CustomCollapse>
                </div>

            </div>
        </div>
    );
}

export default InformationView;
