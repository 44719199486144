import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { cloneElement, useContext, useEffect, useMemo, useRef } from "react";
import { AppContext } from "../../AppContext";
import { Add } from "iconsax-react";
import LoadingIcon from "../../component/utils/Loading";

function Layout() {
    const { state, closeDialog, setLoading, pushNoti, setOrderSocket } = useContext(AppContext);
    const ref = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            closeDialog();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
    }, []);

    return (
        <div className="grid h-screen ">
            <Outlet />

            {/* Loading */}
            {state.loading ? (
                <div className="flex items-center justify-center z-50 absolute backdrop-brightness-[.25] h-screen w-screen bg-white/3">
                    <LoadingIcon />
                </div>
            ) : (
                ""
            )}

            {/* Notifiction */}
            <div className="absolute z-50 right-0 overflow-hidden">
                {state.notifications}
            </div>

            {/* Dialog */}
            {state.dialog.show ? (
                <div className="flex items-center justify-center z-10 absolute backdrop-brightness-[.25] h-screen w-screen bg-white/3">
                    <div className={`${state.dialog.className} relative bg-white p-5 rounded-lg max-w-[90vw] max-h-[90vh] noscroll overflow-y-auto`} ref={ref}>
                        {
                            state.dialog.showIcon === false ?
                                <></>
                                :
                                <div className="flex justify-between items-center py-2 mb-2">
                                    <div className="text-base font-bold">{state.dialog.title}</div>
                                    <Add className="rotate-45 cursor-pointer hover:text-neutral-300" onClick={() => closeDialog()} />
                                </div>
                        }
                        {
                            state.dialog.content ?
                                cloneElement(state.dialog.content, {
                                    classname: "h-[calc(90vh-2.5rem)]",
                                })
                                :
                                <></>
                        }
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default Layout;
