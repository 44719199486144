function WebIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="150" height="80" viewBox="0 0 150 80" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13696 61.4412V45.7141L15.7022 37.1426V71.4285L7.13696 80V64.2961H0V21.4695L2.85498 18.6142V61.4412H7.13696ZM28.6078 64.2961V71.4285L20.0422 80V18.6142H2.85498L5.70996 15.7592H20.0422V8.57149L28.6078 0V61.4376H45.7376L42.8827 64.2961H28.6078ZM45.7376 61.4376V18.5605H41.4557V34.2856L32.8904 42.8571V8.57149L41.4557 0V15.7019H48.5926V58.5793L45.7376 61.4376Z" fill="#1CB78D" />
            <path d="M76.8884 21.4854C76.8884 22.364 76.896 23.2238 76.8846 24.0874C76.8809 24.4645 76.9451 24.5474 77.3419 24.5361C77.9579 24.521 78.5776 24.5248 79.1973 24.5361C79.4732 24.5399 79.5828 24.4456 79.579 24.1628C79.5677 23.2804 79.5752 22.3942 79.5752 21.4891H82.4321V30.0757H79.5752C79.5752 29.7703 79.5752 29.4648 79.5752 29.1594C79.5752 28.5334 79.5677 27.9036 79.579 27.2776C79.5866 26.9948 79.4732 26.9005 79.1973 26.9043C78.5587 26.9156 77.9201 26.9194 77.2814 26.9043C76.9904 26.8968 76.8771 26.9986 76.8846 27.2927C76.8998 28.1035 76.8884 28.918 76.8884 29.7288C76.8884 29.8382 76.8884 29.9513 76.8884 30.0795H74.0353V21.4854H76.8884Z" fill="black" />
            <path d="M72.7543 23.6839H68.6239V24.6568H72.2782C72.2782 25.3506 72.2857 25.9955 72.263 26.6366C72.263 26.6894 72.0628 26.7761 71.957 26.7761C70.9782 26.7874 69.9995 26.7836 69.0207 26.7836C68.6239 26.7836 68.6051 26.8025 68.6051 27.2098C68.6051 27.3945 68.6051 27.5793 68.6088 27.7641C68.6088 27.783 68.6202 27.8018 68.6391 27.8584H72.8941V30.0795H65.7671V21.4854H72.7543V23.6839Z" fill="black" />
            <path d="M62.4001 30.0794H59.4601V23.8082H57V21.4814H64.8564V23.7818H62.4038V30.0794H62.4001Z" fill="black" />
            <path d="M118.853 44.3224V40.6759L118.792 40.657C118.442 41.2528 118.095 41.8524 117.744 42.4482C117.548 42.7763 117.355 43.1082 117.136 43.4212C117.076 43.5079 116.944 43.5833 116.835 43.5946C116.589 43.621 116.337 43.5758 116.095 43.6097C115.801 43.6474 115.665 43.4928 115.529 43.2703C115.039 42.452 114.537 41.6412 114.039 40.8267C114.028 40.8041 114.001 40.7928 113.941 40.7324V44.3224H111.248V35.7358C111.368 35.7283 111.489 35.717 111.61 35.717C112.194 35.717 112.783 35.7283 113.368 35.7094C113.602 35.7019 113.734 35.7886 113.851 35.9809C114.613 37.2593 115.382 38.5302 116.155 39.8048C116.235 39.9367 116.337 40.0536 116.457 40.2196C116.823 39.6049 117.163 39.0317 117.502 38.4547C118.008 37.5949 118.506 36.7314 119.019 35.8754C119.06 35.8037 119.173 35.7283 119.253 35.7283C120.011 35.717 120.773 35.7207 121.554 35.7207V44.3224H118.853Z" fill="black" />
            <path d="M57.0038 44.3603V43.9719C57.0038 41.3209 57.0038 38.6698 57 36.0188C57 35.7737 57.0641 35.604 57.2528 35.423C58.0714 34.6348 58.8636 33.8203 59.6709 33.0171C59.735 32.953 59.8331 32.9228 59.984 32.8398V39.8916L60.0444 39.9067C60.3084 39.5221 60.5763 39.1374 60.8328 38.749C61.3873 37.9043 61.9381 37.0521 62.4889 36.2036C62.5153 36.1621 62.553 36.1282 62.5719 36.0829C62.6964 35.8039 62.885 35.6945 63.2094 35.7058C63.979 35.7322 64.7486 35.7134 65.5332 35.7134V44.3226H62.6964V40.084L62.6209 40.0689C61.9004 41.1776 61.1799 42.2862 60.4593 43.3949C60.282 43.6664 60.1085 43.938 59.9199 44.2019C59.867 44.2736 59.769 44.3528 59.6897 44.3528C58.807 44.3641 57.928 44.3603 57.0038 44.3603Z" fill="black" />
            <path d="M83.6635 39.8501C84.3275 40.1367 84.7575 40.5704 84.931 41.2642C85.1725 42.2183 84.916 42.9913 84.1803 43.6173C83.8031 43.9379 83.3655 44.1679 82.8675 44.2169C82.1885 44.281 81.5057 44.3301 80.8229 44.3414C79.5289 44.3602 78.235 44.3451 76.9184 44.3451V35.7359C76.9674 35.7284 77.024 35.7133 77.0844 35.7133C78.7292 35.7133 80.3739 35.6831 82.015 35.7321C82.7883 35.7548 83.5428 35.9848 84.0936 36.5882C84.5613 37.101 84.7537 37.6931 84.584 38.4096C84.4519 38.9601 84.2143 39.4013 83.7276 39.703C83.6937 39.7256 83.6899 39.7935 83.6673 39.8501H83.6635ZM80.7776 41.0116C80.7587 41.0116 80.7361 41.0116 80.7172 41.0116C80.4117 41.0229 79.9741 40.9211 79.8307 41.0794C79.6685 41.2605 79.7741 41.6753 79.7477 41.9883C79.7289 42.2107 79.8269 42.2749 80.0344 42.2711C80.5173 42.2598 81.0002 42.2673 81.483 42.256C81.8565 42.2485 82.1621 41.8487 82.0489 41.4943C81.9471 41.1737 81.8339 40.9927 81.4265 41.0116C81.2114 41.0191 80.9964 41.0116 80.7776 41.0116ZM80.5701 37.7873C80.0168 37.7974 79.7427 38.0827 79.7477 38.6434C79.7477 38.6962 79.7289 38.7603 79.7515 38.7942C79.8194 38.8809 79.9024 39.0092 79.9854 39.0167C80.3513 39.0393 80.7248 39.0469 81.0907 39.0167C81.4076 38.9941 81.6868 38.7301 81.6754 38.451C81.6641 38.2097 81.6038 37.9231 81.3246 37.8477C81.0832 37.7836 80.8191 37.8062 80.5663 37.7873H80.5701Z" fill="black" />
            <path d="M67.0272 35.7285H69.9621C69.9621 35.853 69.9621 35.9585 69.9621 36.0679C69.9621 36.8598 69.9734 37.6517 69.9583 38.4436C69.9546 38.7152 70.0413 38.7981 70.3129 38.7868C70.9279 38.768 71.5465 38.7717 72.1652 38.7868C72.4519 38.7944 72.5689 38.6963 72.5651 38.3984C72.55 37.6291 72.5613 36.856 72.5613 36.0868V35.7361H75.5V44.3189H72.5764C72.5726 44.2208 72.5613 44.1153 72.5613 44.0059C72.5613 43.1838 72.55 42.3617 72.5651 41.5397C72.5689 41.2417 72.4557 41.1437 72.1652 41.1512C71.5465 41.1663 70.9316 41.1701 70.3129 41.1512C70.0413 41.1437 69.9546 41.2229 69.9583 41.4944C69.9734 42.3165 69.9621 43.1386 69.9621 43.9606C69.9621 44.07 69.9621 44.1831 69.9621 44.3189H67.0272V35.7285Z" fill="black" />
            <path d="M131.23 35.7207H134.161V36.0488C134.161 36.8596 134.169 37.6741 134.158 38.4849C134.158 38.7111 134.225 38.7865 134.456 38.7828C135.123 38.7677 135.795 38.7639 136.463 38.7828C136.723 38.7903 136.772 38.6885 136.768 38.4622C136.761 37.561 136.768 36.6559 136.768 35.732H139.696V44.3186H136.768V41.8222C136.768 41.3772 136.548 41.1547 136.108 41.1547C135.553 41.1547 134.995 41.1661 134.44 41.151C134.218 41.1434 134.158 41.2264 134.161 41.4338C134.169 42.2785 134.161 43.1194 134.161 43.9641V44.3224H131.23V35.7207Z" fill="black" />
            <path d="M88.8544 37.9309V38.9076H92.4156V41.0156C92.2949 41.0232 92.1779 41.0345 92.0572 41.0345C91.0914 41.0345 90.1257 41.0458 89.1562 41.0307C88.9034 41.0269 88.8318 41.1174 88.8431 41.3512C88.8544 41.5964 88.8431 41.8415 88.8431 42.1243H93.0531V44.3266H85.9232V35.7324H92.9098V37.9309H88.8469H88.8544Z" fill="black" />
            <path d="M122.965 44.3268V35.7402H129.948V37.9237H125.915V38.9117H129.457V41.031H128.872C127.967 41.031 127.062 41.0347 126.156 41.0272C125.956 41.0272 125.877 41.0838 125.885 41.2874C125.896 41.5514 125.885 41.8191 125.885 42.1133H130.08V44.3268H122.961H122.965Z" fill="black" />
            <path d="M100.519 38.9223C99.9303 38.1907 99.1947 37.7985 98.244 37.9305C97.8479 37.987 97.5046 38.1718 97.2292 38.466C96.437 39.3031 96.4559 40.8228 97.2481 41.6148C97.8894 42.2558 99.2814 42.5801 100.538 41.1547C101.081 41.6412 101.617 42.1314 102.164 42.6141C102.345 42.7725 102.356 42.9007 102.182 43.0779C101.583 43.6888 100.9 44.1413 100.051 44.3601C99.3531 44.5411 98.6552 44.6504 97.946 44.5637C96.6709 44.4053 95.5241 43.9415 94.6866 42.9195C94.2188 42.3463 93.8642 41.7015 93.7888 40.9435C93.751 40.54 93.668 40.1327 93.6907 39.733C93.7812 38.1869 94.6111 36.671 96.2748 35.9469C97.1349 35.5698 98.0365 35.3926 98.9645 35.4869C100.209 35.6113 101.326 36.0337 102.19 36.9953C102.348 37.1725 102.33 37.2894 102.164 37.4365C101.617 37.9192 101.077 38.4094 100.515 38.9185L100.519 38.9223Z" fill="black" />
            <path d="M107.883 38.0397V44.3222H105.019V43.9527C105.019 42.1237 105.019 40.2948 105.019 38.4658C105.019 38.1818 104.871 38.0397 104.574 38.0397C103.978 38.0397 103.382 38.0397 102.786 38.0397C102.597 38.0397 102.462 37.9794 102.462 37.7682C102.462 37.1007 102.462 36.437 102.462 35.7432H110.448C110.448 36.4333 110.452 37.1083 110.448 37.7833C110.448 37.9945 110.297 38.0397 110.112 38.0397C109.384 38.0359 108.652 38.0397 107.886 38.0397H107.883Z" fill="black" />
            <path d="M140.571 35.7285H148.481C148.481 36.4412 148.481 37.1351 148.481 37.8327C148.481 38.0401 148.323 38.0401 148.172 38.0401C147.546 38.0401 146.916 38.0515 146.29 38.0326C145.999 38.0251 145.901 38.1043 145.905 38.4097C145.92 40.25 145.912 42.0902 145.912 43.9305V44.3227H143.068V38.0401C142.332 38.0401 141.635 38.0251 140.937 38.0477C140.654 38.0552 140.559 37.9723 140.567 37.6894C140.586 37.0446 140.571 36.3998 140.571 35.7323V35.7285Z" fill="black" />
            <path d="M85.8889 58.8943C84.8874 58.8943 83.886 58.883 82.8845 58.8981C82.652 58.8981 82.5507 58.8038 82.4569 58.6152C82.0106 57.7328 81.5567 56.8579 81.0954 55.9831C81.0503 55.9001 80.9378 55.8209 80.8441 55.8096C80.6565 55.7832 80.4615 55.8021 80.2514 55.8021V58.8679H77.322V50.2813H80.2327V53.464C80.4952 53.464 80.7353 53.4716 80.9753 53.4565C81.0316 53.4565 81.0954 53.3735 81.1291 53.3169C81.6842 52.3629 82.2394 51.4088 82.772 50.4397C82.8582 50.2813 82.9445 50.2549 83.0946 50.2549C84.0248 50.2549 84.955 50.2549 85.8852 50.2549V50.5C85.8439 50.5226 85.7801 50.5377 85.7576 50.5754C85.0187 51.7897 84.2798 53.0077 83.5446 54.2258C83.4696 54.3502 83.3946 54.4332 83.4996 54.618C84.1973 55.8209 84.8649 57.0465 85.5476 58.257C85.6301 58.4041 85.7689 58.5172 85.8814 58.6454V58.8905L85.8889 58.8943Z" fill="black" />
            <path d="M69.9103 56.5525H72.5097V50.285H75.4428V56.5675C75.7466 56.5675 76.0242 56.5487 76.2905 56.5789C76.3843 56.5902 76.5381 56.7184 76.5381 56.7938C76.5493 58.0382 76.5418 59.2827 76.5381 60.5233C76.5381 60.5422 76.5231 60.5573 76.5043 60.595H73.875V58.8943H67.0222V50.2812H69.9066V56.5525H69.9103Z" fill="black" />
            <path d="M57 50.2584C58.0315 50.2584 58.9917 50.2546 59.9481 50.2659C60.0382 50.2659 60.1619 50.3413 60.2107 50.4168C60.7321 51.3067 61.2459 52.2005 61.8011 53.1658C61.9511 52.9283 62.0824 52.7322 62.1986 52.5285C62.6112 51.8347 63.0163 51.1333 63.4327 50.4394C63.4814 50.3564 63.594 50.2622 63.6802 50.2622C64.5654 50.2508 65.4544 50.2546 66.3808 50.2546C65.7732 51.303 65.1881 52.3098 64.6029 53.3167C64.1603 54.0822 63.7102 54.8439 63.2751 55.6132C63.2151 55.7188 63.1814 55.8546 63.1814 55.979C63.1739 56.9331 63.1776 57.8872 63.1776 58.8601H60.2407V58.581C60.2407 57.8306 60.2295 57.0802 60.2445 56.3297C60.252 55.9602 60.1957 55.6321 59.9969 55.304C59.3968 54.3235 58.8229 53.328 58.2415 52.3362C57.8439 51.6612 57.4426 50.9862 57.0038 50.2471L57 50.2584Z" fill="black" />
        </svg>
    )
}

export default WebIcon;