import { ArrowLeft, ArrowLeft2, ArrowRight2, Sun, Sun1 } from "iconsax-react";

function OurFundsCard(props) {

    return (
        <div className={`${props.className} flex flex-col gap-3 border bg-white rounded-lg shadow-lg p-3 text-xs`}>
            <div className="text-neutral-600 text-lg font-semibold">Манай сангууд</div>
            <div className="grid grid-rows-3 gap-2">
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        <div className="text-base font-bold">Хөгжил шинэчлэл</div>
                        Хувийн хөрөнгө оруулалтын сан
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        <div className="text-base font-bold">Хийморь сан</div>
                        Хувийн хөрөнгө оруулалтын сан
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        <div className="text-base font-bold">Чөлөөт бүсийн хөгжил шинэчлэл</div>
                        Хөгжил шинэчлэл ХОС
                    </div>
                </div>
            </div>
            <div className="flex gap-3 items-center border-t pt-1 justify-end">Бүх гүйлгээг харах <ArrowRight2 size={15} /></div>
        </div >
    );
}

export default OurFundsCard;
