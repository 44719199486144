import { ArrowDown2 } from "iconsax-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

function NavigationButton(props) {
  const [active, setActive] = useState(props.active);
  const location = useLocation();

  function mouseOver() {
    if (!active) {
      setActive(true);
    }
  }

  function mouseOut() {
    if (active && !props.active) {
      setActive(false);
    }
  }
  return (
    <div className={`${(location.pathname === "/" ? location.pathname : location.pathname.replace(/[/]/g, '')).includes(props.link)
      ? '!text-[#1CB78D] bg-[#CBF4E9] rounded' :
      active ?
        'bg-[#CBF4E9] rounded' :
        ''} flex items-center gap-3 place-content-center py-2 m-2 select-none relative cursor-pointer text-neutral-500 text-sm font-semibold`}
      onMouseOver={mouseOver} onMouseOut={(mouseOut)}
      onClick={props.onClick}
    >
      <div className="absolute w-full h-full top-0 left-0"></div>
      {
        (active || (location.pathname === "/" ? location.pathname : location.pathname.replace(/[/]/g, '')).includes(props.link)) ?
          React.cloneElement(props.children[0], {
            variant: 'Bulk',
            className: 'text-[#1CB78D]',
            size: 20
          }) :
          React.cloneElement(props.children[0], {
            size: 20
          })
      }
      {
        props.isNaviExpand ?
          <div className={`flex justify-between items-center`}>

            <span className={"w-[150px]"}> {props.children[1]} </span>
            {props.hasSubMenu ? <ArrowDown2 size={15} className={`${active === true ? "rotate-180 " : ""}absolute right-[15px]`} /> : <></>}
          </div>
          : ''
      }
      {
        (active || (location.pathname === "/" ? location.pathname : location.pathname.replace(/[/]/g, '')).includes(props.link)) ?
          <span className="absolute h-8 w-1.5 right-0 rounded-l-full bg-primary-6"></span> :
          ''
      }
    </div>
  )
}

export default NavigationButton;