import { ArrowLeft, ArrowLeft2, ArrowRight2, Sun, Sun1 } from "iconsax-react";

function LastTransactionListCard(props) {

    return (
        <div className={`${props.className} flex flex-col border bg-white rounded-lg shadow-lg p-3 text-xs`}>
            <div className="text-neutral-600 text-lg font-semibold">Сүүлд хийсэн гүйлгээнүүд</div>
            <div className="grid grid-rows-3 gap-2 pb-3">
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        Хөгжил шинэчлэл
                        <div className="text-base font-semibold">800,000₮</div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        Хийморь сан
                        <div className="text-base font-semibold">10,000,000₮</div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3 items-center">
                    <img className="bg-neutral-200 h-[50px] w-full" />
                    <div className="col-span-2 flex flex-col text-xs">
                        Чөлөөт бүсийн хөгжил шинэчлэл
                        <div className="text-base font-semibold">300,000₮</div>
                    </div>
                </div>
            </div>
            <div className="flex gap-3 items-center border-t pt-1 justify-end">Бүх гүйлгээг харах <ArrowRight2 size={15} /></div>
        </div >
    );
}

export default LastTransactionListCard;
